const defaultTextStyle = { textAlign: 'center' };

export default {
  numMembers: 40,
  orgType: 'sorority',
  // devModeBorders: 0, // TODO: change
  dimensions: {
    compositeDimensions: { height: null, width: null, unit: null },
    portraitDimensions: { height: 150, width: 90, unit: 'px' },
    portraitImageDimensions: { height: null, width: null, unit: null },
    crestZoneDimensions: { height: null, width: null, unit: null }, // w: 471, h: 405
  },
  compositeBackgroundColor: '#132462',
  compositeBorderStyle: {
    borderColor: 'grey',
    borderWidth: 5,
  },
  creditTextStyle: {
    ...defaultTextStyle,
    fontSize: 12,
    fontFamily: 'open-sans',
    color: 'white',
  },
  crestZone: {
    orgName: '',
    chapterName: '',
    universityName: '',
    years: { from: '2018', to: '2019' },
    crestStyle: {},
    orgTextStyle: {
      ...defaultTextStyle,
      fontFamily: 'old-english-five',
      fontSize: 36,
      color: '#fff',
    },
    chapterTextStyle: {
      ...defaultTextStyle,
      fontFamily: 'old-english-five',
      fontSize: 24,
      color: '#fff',
    },
    yearTextStyle: {
      ...defaultTextStyle,
      fontFamily: 'old-english-five',
      fontSize: 36,
      color: '#fff',
    },
    universityTextStyle: {
      ...defaultTextStyle,
      fontFamily: 'old-english-five',
      fontSize: 24,
      color: '#fff',
    },
    crestImageUrl: 'https://upload.wikimedia.org/wikipedia/en/c/c1/Triangle_Fraternity_Crest.png',
  },
  portraitStyle: {
    portraitImageType: 'rectangle', // ’oval’, ‘rounded’, ‘beveled’, ‘rectangle’
    bevelPercent: 0,
    portraitImageStyle: {
      flex: 1,
    },
    portraitImageBorderStyle: {
      flex: 1,
      padding: 1,
      overflow: 'hidden',
      borderRadius: 0,
      borderWidth: 1, // TODO: remove or change
      borderColor: '#fff',
    },
    portraitNameTextStyle: {
      ...defaultTextStyle,
      fontSize: 12,
      color: '#fff',
      fontFamily: 'open-sans',
    },
    portraitPositionTextStyle: {
      ...defaultTextStyle,
      fontSize: 10,
      color: '#fff',
      fontFamily: 'open-sans',
    },
  },
};
