import React from 'react';
import { Text, TouchableOpacity, View, Platform } from 'react-native';
import { createStackNavigator } from 'react-navigation-stack';
import { createAppContainer, createSwitchNavigator } from 'react-navigation';
import { AppLoading } from 'expo';
import { ApplicationProvider, IconRegistry, Icon } from '@ui-kitten/components';
import { mapping, light as lightTheme } from '@eva-design/eva';
import { EvaIconsPack } from '@ui-kitten/eva-icons';
import * as firebase from 'firebase';
import 'firebase/firestore';
import { loadResourcesAsync } from 'compositor-library';

import BetaFeedbackForm from './src/components/BetaFeedbackForm';
import firebaseConfig from './firebaseConfig.json';
import { Provider as UserProvider } from './src/context/UserContext';
import { Provider as DimensionsProvider } from './src/context/DimensionsContext';
import { Provider as CompositesProvider } from './src/context/CompositesContext';
import LoginScreen from './src/screens/LoginScreen';
import UserHomeScreen from './src/screens/UserHomeScreen';
import CompositeScreen from './src/screens/CompositeScreen';
import CreateCompositeScreen from './src/screens/CreateCompositeScreen';
import PortraitUploadScreen from './src/screens/PortraitUploadScreen';
import RosterScreen from './src/screens/RosterScreen';
import AuthLoadingScreen from './src/screens/AuthLoadingScreen';
import FinishCompositeScreen from './src/screens/FinishCompositeScreen';
import UserUploadScreen from './src/screens/UserUploadScreen';
import ErrorScreen from './src/screens/ErrorScreen';
import EditScreen from './src/screens/EditScreen';
import OnboardingScreen from './src/screens/OnboardingScreen';

firebase.initializeApp(firebaseConfig);

const AppStack = createStackNavigator({
  UserHome: UserHomeScreen,
  Onboarding: OnboardingScreen,
  Composite: CompositeScreen,
  CreateComposite: CreateCompositeScreen,
  PortraitUpload: PortraitUploadScreen,
  Roster: RosterScreen,
  Finish: FinishCompositeScreen,
  Edit: EditScreen,
},
{
  initialRouteName: 'UserHome',
  defaultNavigationOptions: {
    headerTitleAlign: 'center',
    headerStyle: {
      backgroundColor: 'rgba(1,1,1,0)',
      shadowColor: 'transparent',
      borderBottomWidth: 0,
      height: Platform.OS === 'ios' ? 112 : undefined,
    },
    headerTitle: (props) => (
      <View style={{ alignItems: 'center', marginVertical: 10 }}>
        <Text style={{ fontFamily: 'spiffy', color: '#3366ff', fontSize: 28 }}>Spiffy</Text>
        <Text style={{ fontFamily: 'spiffy', color: 'black', fontSize: 14 }}>{props.children}</Text>
      </View>
    ),
    headerTitleStyle: { color: '#3366FF', textAlign: 'center' },
    headerBackTitleVisible: false,
    headerBackImage: () => <Icon name="arrow-ios-back-outline" fill="black" width={32} height={32} />,
  },
});

const AuthStack = createStackNavigator(
  { Login: LoginScreen },
  { defaultNavigationOptions: { headerShown: false } },
);

const UserUploadStack = createStackNavigator(
  { UserUpload: UserUploadScreen },
  {
    defaultNavigationOptions: ({ navigation }) => ({
      title: navigation.state.params.title || navigation.state.params.compositeId,
      headerTitleAlign: 'center',
      headerStyle: {
        backgroundColor: 'rgba(1,1,1,0)',
        fontFamily: 'spiffy',
        shadowColor: 'transparent',
        borderBottomWidth: 0,
        flex: 1,
      },
      headerTitleStyle: { color: '#3366FF', textAlign: 'center' },
      headerBackTitleVisible: true,
      headerTitle: (props) => (
        <View style={{ alignItems: 'center', marginVertical: 10 }}>
          <Text style={{ fontFamily: 'spiffy', color: '#3366ff', fontSize: 28 }}>Spiffy</Text>
          <Text style={{ fontFamily: 'spiffy', color: 'black', fontSize: 14 }}>{props.children}</Text>
        </View>
      ),
      headerBackImage: () => <Icon name="arrow-ios-back-outline" fill="white" width={32} height={32} />,
      headerLeft: () => (navigation.getParam('auth')
        ? (
          <TouchableOpacity
            onPress={() => navigation.navigate('App')}
          >
            <Icon name="home-outline" fill="black" style={{ marginLeft: 20 }} width={32} height={32} />
          </TouchableOpacity>
        )
        : (
          <TouchableOpacity
            onPress={() => navigation.navigate('Auth')}
          >
            <Text style={{ fontSize: 20, color: 'black', marginRight: 20 }}>Login</Text>
          </TouchableOpacity>
        )),
    }),
  },
);

const AppContainer = createAppContainer(
  createSwitchNavigator(
    {
      AuthLoading: AuthLoadingScreen,
      Upload: UserUploadStack,
      Error: ErrorScreen,
      App: AppStack,
      Auth: AuthStack,
    },
    {
      initialRouteName: 'AuthLoading',
      defaultNavigationOptions: {
        headerStyle: { backgroundColor: '#3366FF' },
        headerTitleStyle: { color: 'white' },
        headerBackTitleVisible: false,
        headerBackImage: () => <Icon name="arrow-ios-back-outline" fill="white" width={32} height={32} />,
      },
    },
  ),
);

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assetsLoaded: false,
    };
  }

  _loadAssetsAsync = async () => {
    await loadResourcesAsync();
  };

  render() {
    return this.state.assetsLoaded
      ? (
        <UserProvider>
          <CompositesProvider>
            <DimensionsProvider>
              <ApplicationProvider mapping={mapping} theme={lightTheme}>
                <IconRegistry icons={EvaIconsPack} />
                <AppContainer />
                <BetaFeedbackForm />
              </ApplicationProvider>
            </DimensionsProvider>
          </CompositesProvider>
        </UserProvider>
      )
      : (
        <AppLoading
          startAsync={this._loadAssetsAsync}
          onFinish={() => this.setState({ assetsLoaded: true })}
          onError={console.warn}
        />
      );
  }
}
