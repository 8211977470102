import * as firebase from 'firebase';
import 'firebase/firestore';

import createDataContext from './createDataContext';
import defaultComposite from './defaultComposite';

const defaultContext = {
  userAuth: undefined,
  userData: {
    email: undefined,
    emailVerified: undefined,
    composites: [],
  },
  loginError: undefined,
};

const RESET = 'reset';
const SET_USER_AUTH = 'set_user_auth';
const CREATE_ACCOUNT_FAILURE = 'create_account_failure';
const LOGGING_IN = 'logging_in';
const LOGIN_SUCCESS = 'login_success';
const LOGIN_FAILURE = 'login_failure';
const ONBOARDED = 'onboarded';
const JOIN_COMPOSITE_ERROR = 'join_error';
const JOIN_LOADING = 'join_loading';
const JOIN_COMPOSITE = 'join_composite';

const userReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...payload,
        loginError: undefined,
        createAccountError: undefined,
        loggingIn: false,
      };

    case JOIN_COMPOSITE:
      return {
        ...state,
        userData: {
          ...state.userData,
          composites: action.payload,
        },
      };

    case JOIN_COMPOSITE_ERROR:
      return {
        ...state,
        joinCompositeError: action.payload,
      };

    case JOIN_LOADING:
      return {
        ...state,
        joinCompositeLoading: !state.joinCompositeLoading,
      };

    case 'login_failure':
      return {
        ...state,
        loginError: payload,
        loggingIn: false,
      };

    case CREATE_ACCOUNT_FAILURE:
      return {
        ...state,
        createAccountError: action.payload,
        loggingIn: false,
      };

    case LOGGING_IN:
      return {
        ...state,
        loggingIn: true,
      };

    case 'composite_update':
      return {
        ...state,
        newCompositeLoading: true,
      };

    case 'create_new':
      return {
        ...state,
        userData: {
          ...state.userData,
          composites: [ // new big fat composite object w/ unique composite ID
            ...state.userData.composites,
            action.payload.compositeRef,
          ],
        },
        newCompositeLoading: false,
      };

    case 'composite_loading':
      return {
        ...state,
        compositeLoading: true,
      };

    case 'composite_load_success':
      return {
        ...state,
        compositeData: payload.data,
        selectedRef: payload.ref,
        compositeLoading: false,
      };

    case 'composite_load_failure':
      return { // TODO ADD ERROR CASE HANDLING
        ...state,
        compositeLoading: false,
      };

    case SET_USER_AUTH:
      return {
        userAuth: action.payload.userAuth,
        userData: action.payload.userData || defaultContext.userData,
        userRef: action.payload.userRef,
      };

    case ONBOARDED:
      return {
        ...state,
        userData: {
          ...state.userData,
          onboarded: true,
        },
      };

    case RESET:
      return defaultContext;

    default:
      return state;
  }
};

const joinComposite = (dispatch) => async (newCompositeId, uid, composites) => {
  dispatch({ type: JOIN_LOADING });
  let alreadyIn = false;

  composites.forEach((composite) => {
    if (composite.id === newCompositeId) {
      dispatch({ type: JOIN_COMPOSITE_ERROR, payload: 'You\'re already in this composite!' });
      alreadyIn = true;
    }
  });

  if (!alreadyIn) {
    const compositeRef = await firebase.firestore().collection('composites').doc(newCompositeId);
    await compositeRef.get()
      .then(async (docSnapshot) => {
        if (docSnapshot.exists) {
          dispatch({ type: JOIN_COMPOSITE, payload: [...composites, compositeRef] });
          await firebase.firestore().collection('users').doc(uid)
            .update({ composites: firebase.firestore.FieldValue.arrayUnion(compositeRef) });
        } else {
          dispatch({ type: JOIN_COMPOSITE_ERROR, payload: 'Could not find this composite' });
        }
      });
  }
  dispatch({ type: JOIN_LOADING });
};

// COMPOSITE ACTIONS

const createNewComposite = (dispatch) => async (newCompositeData, userRef) => {
  // newCompositeData = {
  //   compositeName: string,
  //   numMembers: int,
  //   chapterName: string,
  //   university: string,
  //   orgType: 'fraternity' || 'sorority',
  // }
  dispatch({ type: 'composite_update' });

  const newComposite = {
    ...defaultComposite,
    numMembers: newCompositeData.numMembers,
    orgType: `${newCompositeData.orgType}`,
    crestZone: {
      ...defaultComposite.crestZone,
      orgName: `${newCompositeData.organization}`,
      chapterName: `${newCompositeData.chapter}`,
      universityName: `${newCompositeData.university}`,
    },
    // previewUrl: undefined,
    needsNewImage: true, // TODO: always add to updates to composites
  };

  const newMemberData = {
    user: userRef,
    imageUrl: '',
    name: '',
    positions: [],
    semesters: 0,
    special: false,
    execBoard: false,
    isAdmin: true,
  };

  // TODO: THIS IS NOT WORKING CORRECTLY
  await firebase.firestore().collection('composites').add(newComposite)
    .then(async (compositeRef) => {
      await userRef.update({ composites: firebase.firestore.FieldValue.arrayUnion(compositeRef) });
      await compositeRef.collection('admins').doc(userRef.id).set({ type: 'owner' });
      await compositeRef.collection('members').doc(userRef.id).set(newMemberData);
      dispatch({ type: 'create_new', payload: { compositeRef, compositeData: newComposite } });
    })
    .catch((e) => console.log('create new composite error', e));
};

const loadComposite = (dispatch) => async (compositeRef) => {
  dispatch({ type: 'composite_loading' });
  await compositeRef.get()
    .then((doc) => dispatch({ type: 'composite_load_success', payload: { data: doc.data(), ref: compositeRef } }))
    .catch((e) => dispatch({ type: 'composite_load_failure', payload: e }));
};

// END COMPOSITE ACTIONS

// LOGIN ACTIONS

const createUserEmailPassword = (dispatch) => async (email, password) => {
  dispatch({ type: LOGGING_IN });
  await firebase.firestore().collection('users').where('email', '==', email).get()
    .then((query) => {
      if (!query.empty) {
        // TODO: SEND AN EMAIL LINK TO THIS EMAIL TO LOG IN / CREATE PASSWORD
        dispatch({ type: CREATE_ACCOUNT_FAILURE, payload: 'This email is already in use. If it is yours, login or reset your password.' });
      } else {
        firebase.auth().createUserWithEmailAndPassword(email, password)
          .then(async ({ user }) => {
            const { email, emailVerified, uid } = user;
            await firebase.auth().signInWithEmailAndPassword(email, password);
            const userData = { email, emailVerified, composites: [] };
            const userRef = await firebase.firestore().collection('users').doc(uid);
            await userRef.set(userData);
            dispatch({ type: LOGIN_SUCCESS, payload: { userAuth: user, userData, userRef } });
          })
          .catch((e) => {
            dispatch({ type: CREATE_ACCOUNT_FAILURE, payload: e });
          });
      }
    });
};

const loginUserEmailPassword = (dispatch) => (email, password) => {
  dispatch({ type: LOGGING_IN });
  firebase.auth().signInWithEmailAndPassword(email, password)
    .then(async ({ user }) => {
      const userRef = await firebase.firestore()
        .collection('users').doc(user.uid).get();
      const userData = await userRef.data();
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          userAuth: user,
          userData,
          userRef: userRef.ref,
        },
      });
    })
    .catch((e) => {
      dispatch({ type: LOGIN_FAILURE, payload: e.message });
    });
};

const convertUserToPassword = (dispatch) => async (email, password, uid) => {
  dispatch({ type: LOGGING_IN });
  // store user data from user w/ email key
  const userDoc = await firebase.firestore().collection('users').doc(uid).get();
  const userData = await userDoc.data();
  // TODO: add email/password login to existing user instead of replacing user
  // const credential = firebase.auth.EmailAuthProvider.credential(email, password);
  // firebase.auth().currentUser.linkWithCredential(credential)
  //   .then((usercred) => {
  //     console.log('Account linking success', usercred);
  //   })
  //   .catch((error) => {
  //     console.log('Account linking error', error);
  //   });
  // return;
  await firebase.auth().createUserWithEmailAndPassword(email, password)
    .then(async ({ user }) => {
      // on success, we delete the old user with email key
      await firebase.firestore().collection('users').doc(email).delete();
      // then set a new user with uid as key with copied data
      const userRef = await firebase.firestore().collection('users').doc(user.uid).set(userData);
      // iterate thru composites and update the member key to a uid instead of email
      await userData.composites.forEach(async (ref) => {
        const memberDoc = await ref.collection('members').doc(email).get();
        const memberData = await memberDoc.data();
        await ref.collection('members').doc(email).delete();
        await ref.collection('members').doc(user.uid).set(memberData);
      });
      dispatch({ type: LOGIN_SUCCESS, payload: { userAuth: user, userData, userRef } });
    })
    .catch((e) => dispatch({ type: LOGIN_FAILURE, payload: e.message }));
};

const setOnboarded = (dispatch) => async (uid, bool) => {
  firebase.firestore().collection('users').doc(uid).update({
    onboarded: bool,
  })
    .then(() => {
      dispatch({ type: ONBOARDED });
    })
    .catch((e) => console.log(e));
};

const sendResetPasswordEmail = () => async (email) => {
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be whitelisted in the Firebase Console.
    url: 'http://spiffycomposites.com',
    // This must be true.
    handleCodeInApp: true,
  };

  firebase.auth().sendPasswordResetEmail(email, actionCodeSettings).then(() => {
    console.log('email sent');
  }).catch((e) => {
    console.log('somethin happened', e);
  });
};

const setUserAuth = (dispatch) => async (userAuth) => {
  await firebase.firestore().collection('users').doc(userAuth.uid).get()
    .then(async (userDoc) => dispatch({
      type: SET_USER_AUTH,
      payload: { userAuth, userData: await userDoc.data(), userRef: userDoc.ref },
    }))
    .catch((e) => console.log('error setting user auth', e));
};

// END LOGIN ACTIONS

const resetUserContext = (dispatch) => () => dispatch({ type: RESET });

export const { Provider, Context } = createDataContext(
  userReducer,
  {
    joinComposite,
    createUserEmailPassword,
    loginUserEmailPassword,
    createNewComposite,
    loadComposite,
    resetUserContext,
    setUserAuth,
    convertUserToPassword,
    sendResetPasswordEmail,
    setOnboarded,
  },
  defaultContext,
);
