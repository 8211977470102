import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import { Button } from '@ui-kitten/components';

import { Context as UserContext } from '../context/UserContext';

const ErrorScreen = ({ navigation }) => {
  const { state } = useContext(UserContext);
  const errorMessage = navigation.getParam('message');

  return (
    <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
      <Text style={{ fontSize: 128, fontFamily: 'spiffy', margin: 30 }}>Spiffy</Text>
      <Text style={{ fontSize: 32, margin: 30 }}>Sorry, couldn't find that page for you.</Text>
      <Text style={{ fontSize: 20, margin: 30, alignSelf: 'center' }}>{errorMessage}</Text>
      <Button
        size="giant"
        onPress={() => {
          state.userAuth
            ? navigation.navigate('UserHome')
            : navigation.navigate('Auth');
        }}
      >
        Home
      </Button>
    </View>
  );
};

export default ErrorScreen;
