import { Dimensions } from 'react-native';
import createDataContext from './createDataContext';

const UPDATE_DIMENSIONS = 'update_dimensions';

const dimensionsReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_DIMENSIONS:
      return {
        screenWidth: Dimensions.get('window').width,
        screenHeight: Dimensions.get('window').height,
        isPortrait: Dimensions.get('window').width < Dimensions.get('window').height,
      };
    default:
      return state;
  }
};

const updateDimensions = (dispatch) => () => {
  dispatch({ type: UPDATE_DIMENSIONS });
};

export const { Context, Provider } = createDataContext(
  dimensionsReducer,
  { updateDimensions },
  {
    screenWidth: Dimensions.get('window').width,
    screenHeight: Dimensions.get('window').height,
    isPortrait: Dimensions.get('window').width < Dimensions.get('window').height,
  },
);
