import React, { useEffect, useContext } from 'react';
import {
  View,
  StyleSheet,
  Image,
  Dimensions,
  AsyncStorage,
} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { Context as UserContext } from '../context/UserContext';
import { Context as DimensionsContext } from '../context/DimensionsContext';
import LoginBox from '../components/LoginBox';

const LoginScreen = ({ navigation }) => {
  const { state } = useContext(UserContext);
  const { userAuth } = state;
  const dimensions = useContext(DimensionsContext);
  const { updateDimensions } = dimensions;
  const { isPortrait } = dimensions.state;

  const email = navigation.getParam('email');

  useEffect(() => {
    Dimensions.addEventListener('change', updateDimensions);
    return () => {
      Dimensions.removeEventListener('change', updateDimensions);
    };
  }, []);

  useEffect(() => {
    async function login() {
      if (userAuth !== undefined) {
        await AsyncStorage.setItem('userAuth', JSON.stringify(userAuth));
        navigation.navigate('App');
      }
    }
    login();
  }, [state.userAuth]);

  return (
    <View style={{ flex: 1 }}>
      <Image
        source={require('../../assets/images/loginBackground.png')}
        style={{ ...StyleSheet.absoluteFillObject, width: undefined, height: undefined }}
      />
      <KeyboardAwareScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ flex: 1, alignItems: 'center', flexDirection: 'row' }}
        scrollEnabled={false}
        showsVerticalScrollIndicator={false}
      >
        {isPortrait ? null : <View style={{ flex: 2 }} />}
        <LoginBox navigation={navigation} email={email} />
      </KeyboardAwareScrollView>
    </View>
  );
};

LoginScreen.navigationOptions = {
  title: 'Login Screen yo',
  headerShown: false,
};

const styles = StyleSheet.create({
  screen: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default LoginScreen;
