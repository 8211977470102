import React, { useState, useContext } from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Button, Input, Select } from '@ui-kitten/components';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { Context as UserContext } from '../context/UserContext';
import { Context as DimensionsContext } from '../context/DimensionsContext';

const CreateCompositeScreen = ({ navigation }) => {
  const [newCompositeData, setNewCompositeData] = useState({
    orgType: undefined, // 'fraternity' || 'sorority'
    numMembers: undefined, // number 1-350
    university: undefined, // string
    organization: 'Triangle Fraternity', // string
    chapter: undefined, // string
  });
  const [selectedOrg, setSelectedOrg] = useState({ text: 'Triangle Fraternity' });

  const userStore = useContext(UserContext); // for screen responsiveness
  const { userRef } = userStore.state;
  const { createNewComposite } = userStore;

  const dimensions = useContext(DimensionsContext);
  const { screenWidth, isPortrait } = dimensions.state;

  const [buttonWidth, setButtonWidth] = useState(null);

  CreateCompositeScreen.navigationOptions = () => ({
    title: 'New Composite',
  });

  const inputWidth = isPortrait ? 0.8 * screenWidth : 0.6 * screenWidth;

  const onCreatePress = () => {
    // TODO: first verify correct info has been added, then navigate back
    // numMembers from 0 to 350
    createNewComposite(newCompositeData, userRef);
    navigation.pop();
  };

  // if (compositeStore.state.isLoading === false && userStore.state.isLoading === undefined) {

  // }

  return (
    <KeyboardAwareScrollView
      extraScrollHeight={100}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{ flexGrow: 1 }}
      enableOnAndroid
    >
      <View style={styles.container}>
        <Text style={styles.headerText}>Your Organization</Text>
        <View style={{ flexDirection: 'row' }}>
          <Button
            style={[styles.button, { width: buttonWidth }]}
            onLayout={({ nativeEvent }) => setButtonWidth(nativeEvent.layout.width)}
            status="primary"
            size="large"
            appearance={newCompositeData.orgType === 'fraternity' ? 'filled' : 'outline'}
            onPress={() => setNewCompositeData({ ...newCompositeData, orgType: 'fraternity' })}
          >
            Fraternity
            </Button>
          <Button
            style={[styles.button, { width: buttonWidth }]}
            status="primary"
            size="large"
            appearance={newCompositeData.orgType === 'sorority' ? 'filled' : 'outline'}
            onPress={() => setNewCompositeData({ ...newCompositeData, orgType: 'sorority' })}
          >
            Sorority
            </Button>
        </View>
        <View>
          <Text style={styles.instructionText}>Greek Organization</Text>
          <Select
            data={[{ text: 'Triangle Fraternity' }]}
            style={[styles.input, { width: inputWidth }]}
            selectedOption={selectedOrg}
            onSelect={(newOrg) => {
              setSelectedOrg(newOrg);
              setNewCompositeData({ ...newCompositeData, organization: newOrg.text });
            }}
          />
          {/* <Input
            style={[styles.input, { width: inputWidth }]}
            status="primary"
            placeholder="Your Fraternity/Sorority"
            value={newCompositeData.organization}
            onChangeText={(text) => setNewCompositeData({ ...newCompositeData, organization: text })}
          /> */}
        </View>
        <Text style={styles.headerText}>Your Chapter</Text>
        <View>
          <Text style={styles.instructionText}>Chapter Name</Text>
          <Input
            style={[styles.input, { width: inputWidth }]}
            status="primary"
            placeholder="Your Chapter"
            value={newCompositeData.chapter}
            onChangeText={(text) => setNewCompositeData({ ...newCompositeData, chapter: text })}
          />
        </View>
        <View>
          <Text style={styles.instructionText}>University</Text>
          <Input
            style={[styles.input, { width: inputWidth }]}
            status="primary"
            placeholder="Your University"
            value={newCompositeData.university}
            onChangeText={(text) => setNewCompositeData({ ...newCompositeData, university: text })}
          />
        </View>
        <View>
          <Text style={styles.instructionText}>Number of Members</Text>
          <Input
            style={[styles.input, { width: inputWidth }]}
            status="primary"
            placeholder="Number of members in your chapter"
            value={newCompositeData.numMembers}
            onChangeText={(text) => setNewCompositeData({ ...newCompositeData, numMembers: text })}
          />
        </View>
        <Button
          status="success"
          size="giant"
          onPress={onCreatePress}
          style={[styles.createButton, { width: 0.5 * screenWidth }]}
        >
          Create
          </Button>
      </View>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  instructionText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#575757',
    marginTop: 8,
    marginLeft: 8,
  },
  headerText: {
    fontSize: 32,
    fontWeight: 'bold',
    marginVertical: 10,
    color: '#3366FF',
  },
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  input: {
    margin: 8,
  },
  createButton: {
    alignSelf: 'center',
    backgroundColor: '#3366ff',
    borderWidth: 0,
    margin: 10,
  },
  button: {
    margin: 8,
  },
});

CreateCompositeScreen.navigationOptions = {
  title: 'Create New Composite',
};

export default CreateCompositeScreen;
