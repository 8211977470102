import React, { useState, useContext } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { Button, Icon, Input, Spinner } from '@ui-kitten/components';

import { Context as UserContext } from '../context/UserContext';

const isValidEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return emailFormat.test(email);
};

const LoginBox = (props) => {
  const [loginState, setLoginState] = useState({
    loginEmail: '',
    loginPassword: '',
    createAccountEmail: props.email || '',
    createAccountPassOne: '',
    createAccountPassTwo: '',
    loginError: '',
    createAccountError: '',
  });
  const [resetPassword, setResetPassword] = useState({
    showing: false,
    email: '',
    error: '',
    emailSent: false,
  });
  const userStore = useContext(UserContext);
  const {
    state,
    createUserEmailPassword,
    loginUserEmailPassword,
    sendResetPasswordEmail,
  } = userStore;
  const { loginError, loggingIn, createAccountError } = state;

  const onPasswordReset = async (email) => {
    isValidEmail(email)
      ? sendResetPasswordEmail(email)
        .then(() => setResetPassword({ ...resetPassword, emailSent: true }))
      : setResetPassword({ ...resetPassword, error: 'Invalid email' });
  };

  const loginWithEmail = async () => {
    setLoginState({ ...loginState, loginError: '' });
    const { loginEmail, loginPassword } = loginState;
    loginUserEmailPassword(loginEmail, loginPassword);
  };

  const createAccountWithEmail = async () => {
    setLoginState({ ...loginState, createAccountError: '' });
    const { createAccountEmail, createAccountPassOne, createAccountPassTwo } = loginState;
    if (createAccountEmail && createAccountPassOne && createAccountPassTwo !== '') {
      if (isValidEmail(createAccountEmail)) {
        if (createAccountPassOne === createAccountPassTwo) {
          if (createAccountPassOne.length >= 8) {
            createUserEmailPassword(createAccountEmail, createAccountPassOne);
          } else {
            setLoginState({ ...loginState, createAccountError: 'Password must be at least 8 characters long' });
          }
        } else {
          setLoginState({ ...loginState, createAccountError: 'Passwords do not match' });
        }
      } else {
        setLoginState({ ...loginState, createAccountError: 'Invalid email address' });
      }
    } else {
      setLoginState({ ...loginState, createAccountError: 'Empty field' });
    }
  };

  const { instructionText } = props;

  return (
    <View style={styles.loginBox}>
      <Text style={styles.spiffy}>Spiffy</Text>
      {instructionText ? <Text style={styles.instructionText}>{instructionText}</Text> : null}

      <Text style={styles.instructionText}>Login</Text>
      <Input
        style={styles.input}
        autoCorrect={false}
        status={loginState.loginError || loginError ? 'danger' : 'primary'}
        placeholder="Email"
        value={loginState.loginEmail}
        onChangeText={(text) => setLoginState({ ...loginState, loginEmail: text })}
        onSubmitEditing={loginWithEmail}
      />
      <Input
        style={styles.input}
        status={loginState.loginError || loginError ? 'danger' : 'primary'}
        placeholder="Password"
        secureTextEntry
        value={loginState.loginPassword}
        caption={`${loginError || loginState.loginError}`}
        onChangeText={(text) => setLoginState({ ...loginState, loginPassword: text })}
        onSubmitEditing={loginWithEmail}
      />
      {loggingIn
        ? <View style={[styles.button, { alignItems: 'center' }]}><Spinner /></View>
        : (
          <Button
            icon={(style) => <Icon name="log-in-outline" {...style} />}
            onPress={loginWithEmail}
            style={styles.button}
          >
            Login
          </Button>
        )}

      <Text style={styles.instructionText}>Create Account</Text>
      <Input
        style={styles.input}
        status={loginState.createAccountError || createAccountError ? 'danger' : 'primary'}
        placeholder="Email"
        autoCorrect={false}
        value={loginState.createAccountEmail}
        onChangeText={(text) => setLoginState({ ...loginState, createAccountEmail: text })}
        onSubmitEditing={createAccountWithEmail}
      />
      <Input
        style={styles.input}
        status={loginState.createAccountError || createAccountError ? 'danger' : 'primary'}
        placeholder="Password"
        secureTextEntry
        value={loginState.createAccountPassOne}
        onChangeText={(text) => setLoginState({ ...loginState, createAccountPassOne: text })}
        onSubmitEditing={createAccountWithEmail}
      />
      <Input
        style={styles.input}
        status={loginState.createAccountError || createAccountError ? 'danger' : 'primary'}
        placeholder="Repeat Password"
        secureTextEntry
        value={loginState.createAccountPassTwo}
        caption={`${createAccountError || loginState.createAccountError}`}
        onChangeText={(text) => setLoginState({ ...loginState, createAccountPassTwo: text })}
        onSubmitEditing={createAccountWithEmail}
      />
      {loggingIn
        ? <View style={[styles.button, { alignItems: 'center' }]}><Spinner /></View>
        : (
          <Button
            icon={(style) => <Icon name="person-outline" {...style} />}
            onPress={createAccountWithEmail}
            style={styles.button}
          >
            Create Account
          </Button>
        )}
      <TouchableOpacity
        onPress={() => setResetPassword({ ...resetPassword, showing: !resetPassword.showing })}
      >
        <Text style={{ alignSelf: 'center', marginBottom: 12, color: '#3366FF' }}>Reset Password</Text>
      </TouchableOpacity>
      {resetPassword.showing
        ? (
          <>
            <Input
              style={styles.input}
              autoCorrect={false}
              status={resetPassword.error ? 'danger' : 'primary'}
              placeholder="Email"
              value={resetPassword.email}
              onChangeText={(text) => setResetPassword({ ...resetPassword, email: text })}
            />
            {resetPassword.emailSent
              ? <Text style={{ alignSelf: 'center' }}>Email sent</Text>
              : (
                <Button
                  onPress={() => onPasswordReset(resetPassword.email)}
                  style={styles.button}
                >
                  Reset
                </Button>
              )}
          </>
        ) : null}
    </View>
  );
};

const styles = StyleSheet.create({
  loginBox: {
    flex: 1,
    marginHorizontal: 30,
    backgroundColor: 'rgba(255,255,255,0.8)',
    borderRadius: 10,
    minWidth: 320,
  },
  spiffy: {
    margin: 15,
    fontSize: 72,
    fontFamily: 'spiffy',
    alignSelf: 'center',
    color: '#3366FF',
  },
  instructionText: {
    fontSize: 26,
    fontWeight: 'bold',
    color: '#575757',
    marginLeft: 12,
  },
  input: {
    margin: 6,
    marginHorizontal: 12,
  },
  button: {
    margin: 6,
    marginHorizontal: 12,
    marginBottom: 12,
  },
});

export default LoginBox;
