import React, { useState, useContext } from 'react';
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import { Icon, Button, Input, Spinner, Select } from '@ui-kitten/components';
import * as ImagePicker from 'expo-image-picker';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import * as ImageManipulator from 'expo-image-manipulator';

import { Context as UserContext } from '../context/UserContext';
import { Context as DimensionsContext } from '../context/DimensionsContext';
import { Context as CompositesContext } from '../context/CompositesContext';
import Portrait from '../components/Portrait';

const MAX_POSITIONS = 2;

// We'll receive a user ID & composite ID from navigation prop
const PortraitUploadScreen = ({ navigation }) => {
  PortraitUploadScreen.navigationOptions = () => ({
    title: 'Your Portrait',
  });
  const compositeId = navigation.getParam('compositeId');

  // Dimensions
  const dimensions = useContext(DimensionsContext);
  const { screenWidth, isPortrait } = dimensions.state;

  // Composites context
  const compositesStore = useContext(CompositesContext);
  const { saveUserData } = compositesStore;
  const {
    compositesData: { [compositeId]: compositeData },
    compositesUserData: { [compositeId]: compositeUserData },
  } = compositesStore.state;

  const [userPortraitData, setUserPortraitData] = useState({
    name: compositeUserData.name,
    positions: compositeUserData.positions,
    imageUrl: compositeUserData.imageUrl,
    seniority: undefined,
    newPhoto: false,
    execBoard: false,
    special: false,
    semesters: compositeUserData.semesters,
  });

  const updatePortrait = async (image) => {

    await ImageManipulator.manipulateAsync(image.uri, [])
      .then(async (img) => {
        if (img.height < img.width) {
          await ImageManipulator.manipulateAsync(image.uri, [{ rotate: -90 }])
            .then((final) => {
              setUserPortraitData({
                ...userPortraitData,
                imageUrl: final.uri,
                newPhoto: true,
              });
            });
        } else {
          setUserPortraitData({
            ...userPortraitData,
            imageUrl: img.uri,
            newPhoto: true,
          });
        }
      });
  };

  // ImagePicker docs https://docs.expo.io/versions/latest/sdk/imagepicker/
  const onUploadPress = async () => {
    const { granted } = await ImagePicker.requestCameraRollPermissionsAsync();
    if (granted) {
      const response = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        quality: 1,
        exif: true,
        base64: true,
        allowsEditing: true,
      });
      if (!response.cancelled) updatePortrait(response);
    }
  };

  // const onTakePhotoPress = async () => {
  //   const { cancelled } = await ImagePicker.requestCameraPermissionsAsync();
  //   if (!cancelled) {
  //     const response = await ImagePicker.launchCameraAsync({
  //       quality: 1,
  //       exif: true,
  //       aspect: [4, 3],
  //     });
  //     if (!response.cancelled) updatePortrait(response);
  //   }
  // };

  // User store & saving user data
  const userStore = useContext(UserContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const onSavePress = async () => {
    setSaveLoading(true);
    await saveUserData(compositeId, userStore.state.userAuth.uid, userPortraitData)
    setSaveLoading(false);
    navigation.pop();
  };

  const [semesters, setSemesters] = useState({
    text: userPortraitData.semesters === 10 ? '10+' : userPortraitData.semesters.toString(),
    value: userPortraitData.semesters,
  });

  const portraitWidth = 0.4 * screenWidth;
  const inputWidth = 0.8 * screenWidth;

  return (
    <KeyboardAwareScrollView
      extraScrollHeight={100}
      showsVerticalScrollIndicator={false}
    >
      <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
        <Portrait
          portraitStyle={compositeData.portraitStyle}
          name={userPortraitData.name}
          positions={userPortraitData.positions}
          height={(portraitWidth * 5) / 3}
          imageUrl={userPortraitData.imageUrl || 'https://bdsi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png'}
        />
        <View>
          <View style={{ justifyContent: 'center', alignItems: 'center' }}>
            {/* <Button
              icon={(style) => <Icon name="camera" {...style} />}
              style={{ margin: 8, width: inputWidth }}
              status="primary"
              onPress={onTakePhotoPress}
            >
              Take Photo
            </Button> */}
            <Button
              icon={(style) => <Icon name="upload" {...style} />}
              style={{ margin: 8, width: inputWidth }}
              status="primary"
              onPress={onUploadPress}
            >
              Upload Photo
            </Button>
          </View>
          <Text style={styles.instructionText}>Name</Text>
          <Input
            style={[styles.input, { width: inputWidth }]}
            status="primary"
            placeholder="Your Name"
            value={userPortraitData.name}
            onChangeText={(text) => setUserPortraitData({ ...userPortraitData, name: text })}
          />
          <Text style={styles.instructionText}>Position(s)</Text>
          {userPortraitData.positions.map((position, i) => (
            <View key={i} style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Input
                style={[styles.input, { width: inputWidth - 36 }]}
                status="primary"
                placeholder="Your Position"
                value={userPortraitData.positions[i]}
                onChangeText={(text) => {
                  const { positions } = userPortraitData;
                  positions[i] = text;
                  setUserPortraitData({ ...userPortraitData, positions });
                }}
              />
              <TouchableOpacity onPress={() => setUserPortraitData({
                ...userPortraitData,
                positions: userPortraitData.positions.filter((pos, index) => index !== i),
              })}
              >
                <Icon name="close-outline" style={{ width: 36, height: 36 }} />
              </TouchableOpacity>
            </View>
          ))}
          {userPortraitData.positions.length > 0
            ? (
              <View style={{ flexDirection: 'row' }}>
                <Button
                  style={{ width: (inputWidth / 2) - 8, margin: 8 }}
                  size="small"
                  appearance={userPortraitData.execBoard ? 'filled' : 'outline'}
                  onPress={() => setUserPortraitData({ ...userPortraitData, execBoard: !userPortraitData.execBoard })}
                >
                  Exec
                </Button>
                <Button
                  style={{ width: (inputWidth / 2) - 8, margin: 8 }}
                  size="small"
                  appearance={userPortraitData.special ? 'filled' : 'outline'}
                  onPress={() => setUserPortraitData({ ...userPortraitData, special: !userPortraitData.special })}
                >
                  Special
                </Button>
              </View>
            )
            : null}
          {userPortraitData.positions.length < MAX_POSITIONS
            ? (
              <View style={{ flexDirection: 'column' }}>
                <Button
                  icon={(style) => <Icon name="plus-outline" {...style} />}
                  style={{ height: 40, width: inputWidth, margin: 8 }}
                  onPress={() => setUserPortraitData({ ...userPortraitData, positions: [...userPortraitData.positions, ''] })}
                >
                  Add New
                </Button>
              </View>
            )
            : null}
          {/* {userPortraitData.positions.map((position) => (
          <View key={userPortraitData.positions.indexOf(position)} style={styles.positionView}>
            <Text style={styles.positionText}>{position}</Text>
            <TouchableOpacity onPress={() => setUserPortraitData({
              ...userPortraitData,
              positions: userPortraitData.positions.filter((pos) => pos !== position),
            })}
            >
              <Icon name="close-outline" style={{ width: 18, height: 18, margin: 5 }} />
            </TouchableOpacity>
          </View>
        ))} */}
          <Text style={styles.instructionText}>Semesters</Text>
          <Select
            style={{ margin: 8 }}
            data={[{ text: '0', value: 0 }, { text: '1', value: 1 }, { text: '2', value: 2 }, { text: '3', value: 3 },
            { text: '4', value: 4 }, { text: '5', value: 5 }, { text: '6', value: 6 }, { text: '7', value: 7 },
            { text: '8', value: 8 }, { text: '9', value: 9 }, { text: '10+', value: 10 }]}
            selectedOption={semesters}
            onSelect={(option) => {
              setSemesters(option);
              setUserPortraitData({ ...userPortraitData, semesters: option.value });
            }}
          />
          <View style={{ alignItems: 'center', justifyContent: 'center', marginBottom: 40, marginTop: 10 }}>
            {saveLoading
              ? <Spinner status="success" />
              : (
                <Button
                  onPress={onSavePress}
                  size="giant"
                  style={[styles.button, { width: 0.4 * screenWidth }]}
                >
                  Save
                </Button>
              )}
          </View>
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  instructionText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#575757',
    marginTop: 8,
    marginLeft: 8,
  },
  input: {
    margin: 8,
  },
  positionView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 8,
    // borderWidth: 2,
    borderColor: '#3366FF',
    borderRadius: 4,
    backgroundColor: 'white',
  },
  button: {
    alignSelf: 'center',
    backgroundColor: '#3DB92B',
    borderWidth: 0,
    margin: 10,
  },
  positionText: {
    fontSize: 18,
    margin: 8,
    color: '#575757',
    fontWeight: 'bold',
    alignSelf: 'center',
  },
});

export default PortraitUploadScreen;
