import React, { useContext, useRef, useState } from 'react';
import { StyleSheet, View, ScrollView, Text, Image } from 'react-native';
import { Button, Icon, Input } from '@ui-kitten/components';
import { Linking } from 'expo';

import { Context as DimensionsContext } from '../context/DimensionsContext';
import { Context as UserContext } from '../context/UserContext';

const OnboardingScreen = ({ navigation }) => {
  OnboardingScreen.navigationOptions = () => ({
    headerShown: false,
  });

  const { state } = useContext(DimensionsContext);
  const { screenWidth, screenHeight, isPortrait } = state;

  const {
    state: { joinCompositeError, userAuth, userData },
    joinComposite,
  } = useContext(UserContext);

  const scrollViewRef = useRef();
  const scroll = (page) => scrollViewRef.current.scrollTo({
    x: page * screenWidth,
    y: 0,
    animated: true,
  });

  const [joinControl, setJoinControl] = useState({
    joinCompositeError: '',
    showJoin: false,
  });

  const onJoinComposite = () => {
    const { queryParams } = Linking.parse(joinControl.joinText);

    if (queryParams.compositeId) {
      joinComposite(queryParams.compositeId, userAuth.uid, userData.composites);
      // navigation.navigate('UserHome');
    } else {
      setJoinControl({ ...joinControl, joinError: 'That doesn\'t seem to be a link' });
    }
  };

  return (
    <ScrollView
      ref={scrollViewRef}
      pagingEnabled
      horizontal
      showsHorizontalScrollIndicator={false}
      scrollEventThrottle={200}
      contentContainerStyle={{ width: 4 * screenWidth }}
      decelerationRate="fast"
    >
      <View style={[styles.container, { width: screenWidth }]}>
        <View style={{ alignItems: 'center' }}>
          <Text style={[styles.titleTextStyle, { marginBottom: 0 }]}>Welcome to</Text>
          <Text style={[styles.titleTextStyle, { fontSize: 84 }]}>Spiffy</Text>
        </View>
        <Image
          source={require('../../assets/images/Onboarding1.png')}
          style={{
            width: isPortrait ? screenWidth * 0.8 : screenWidth * 0.5,
            height: screenHeight * 0.5,
            resizeMode: 'contain',
          }}
        />
        <Text style={styles.descriptionTextStyle}>
          Get your composite done quickly, easily, and at a low cost.
        </Text>
        <Button style={styles.nextButton} status="control" onPress={() => scroll(1)}>Next</Button>
      </View>

      <View style={[styles.container, { width: screenWidth }]}>
        <Text style={styles.titleTextStyle}>Create and Share</Text>
        <Image
          source={require('../../assets/images/Onboarding2.png')}
          style={{
            width: isPortrait ? screenWidth * 0.8 : screenWidth * 0.5,
            height: screenHeight * 0.5,
            resizeMode: 'contain',
          }}
        />
        <Text style={styles.descriptionTextStyle}>
          Create a composite and share the link with your organization.
        </Text>
        <Button style={styles.nextButton} status="control" onPress={() => scroll(2)}>Next</Button>
      </View>

      <View style={[styles.container, { width: screenWidth }]}>
        <Text style={styles.titleTextStyle}>Upload your Portraits</Text>
        <Image
          source={require('../../assets/images/Onboarding3.png')}
          style={{
            width: isPortrait ? screenWidth * 0.8 : screenWidth * 0.5,
            height: screenHeight * 0.5,
            resizeMode: 'contain',
          }}
        />
        <Text style={styles.descriptionTextStyle}>
          Upload your portrait and enter your information.
        </Text>
        <Button style={styles.nextButton} status="control" onPress={() => scroll(3)}>Next</Button>
      </View>
      <View style={[styles.container, { width: screenWidth, justifyContent: 'center' }]}>
        <Text style={styles.titleTextStyle}>Get Started!</Text>
        <Button
          icon={(style) => <Icon name="plus-outline" {...style} />}
          onPress={() => navigation.navigate('CreateComposite')}
          size="giant"
          status="control"
          style={styles.button}
        >
          Create New Composite
        </Button>
        {!joinControl.showJoin ? (
          <Button
            icon={(style) => <Icon name="diagonal-arrow-right-up" {...style} />}
            onPress={() => setJoinControl({ ...joinControl, showJoin: true })}
            size="giant"
            status="control"
            style={styles.button}
          >
            Join a Composite
          </Button>
        )
          : (
            <View style={{
              marginBottom: 20,
              flexDirection: 'row',
              width: 300,
              alignSelf: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <Input
                style={{ width: 210 }}
                placeholder="Paste join link"
                status={joinControl.joinError || joinCompositeError ? 'danger' : 'primary'}
                caption={joinControl.joinError || joinCompositeError}
                onChangeText={(text) => setJoinControl({
                  ...joinControl,
                  joinText: text,
                })}
              />
              <Button
                style={[styles.button, { width: 80, marginLeft: 10 }]}
                status="control"
                onPress={onJoinComposite}
              >
                Join
              </Button>
            </View>
          )}
        <Button
          size="large"
          style={styles.nextButton}
          onPress={() => navigation.navigate('UserHome')}
        >
          Home
        </Button>
      </View>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#3366ff',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  titleTextStyle: {
    fontSize: 32,
    color: 'white',
    fontFamily: 'spiffy',
    marginBottom: 15,
  },
  button: {
    backgroundColor: 'white',
    borderWidth: 0,
    marginVertical: 20,
    width: 300,
  },
  nextButton: {
    marginVertical: 20,
    width: 200,
  },
  descriptionTextStyle: {
    marginHorizontal: 10,
    fontSize: 18,
    color: 'white',
    fontFamily: 'spiffy',
    marginTop: 15,
    textAlign: 'center',
  },
});

export default OnboardingScreen;
