import React from 'react';
import { View } from 'react-native';

import Svg, { ClipPath, Image, Defs, Polyline, Polygon, Ellipse } from 'react-native-svg';

const PortraitImageBorder = ({ imageUrl, height, width, portraitStyle, children }) => {
  const { bevelPercent, portraitImageBorderStyle } = portraitStyle;

  // https://drive.google.com/uc?export=view&id=1u1FFcY323n4LTz9UUPjtHGwLFeDQozR-

  if (portraitStyle.portraitImageType === 'oval') {
    return (
      <View style={{ flex: 1 }}>
        <Svg height="100%" width="100%">
          <Defs>
            <ClipPath id="oval">
              <Ellipse
                cx={`${Math.floor(width / 2)}`}
                cy={`${Math.floor(height / 2)}`}
                rx={`${Math.floor(width / 2) - portraitImageBorderStyle.borderWidth}`}
                ry={`${Math.floor(height / 2) - portraitImageBorderStyle.borderWidth}`}
                // strokeWidth={portraitImageBorderStyle.borderWidth}
              // fill="transparent"
              // stroke={`${portraitImageBorderStyle.borderColor}`}
              />
            </ClipPath>
          </Defs>
          <Image
            x={portraitImageBorderStyle.borderWidth}
            y={portraitImageBorderStyle.borderWidth}
            width={width - 2 * portraitImageBorderStyle.borderWidth}
            height={height - 2 * portraitImageBorderStyle.borderWidth}
            preserveAspectRatio="xMidYMid slice"
            href={imageUrl}
            clipPath="url(#oval)"
          />
          <Ellipse
            cx={`${Math.floor(width / 2)}`}
            cy={`${Math.floor(height / 2)}`}
            rx={`${Math.floor(width / 2) - portraitImageBorderStyle.borderWidth / 2}`}
            ry={`${Math.floor(height / 2) - portraitImageBorderStyle.borderWidth / 2}`}
            strokeWidth={portraitImageBorderStyle.borderWidth}
            fill="transparent"
            stroke={`${portraitImageBorderStyle.borderColor}`}
          />
        </Svg>
      </View>
    );
  }

  if (portraitStyle.portraitImageType === 'beveled') {
    const halfBorderWidth = Math.ceil(portraitImageBorderStyle.borderWidth / 2);

    const points = `${Math.floor(bevelPercent * width)},${halfBorderWidth} 
    ${Math.floor(width - (bevelPercent * width))},${halfBorderWidth} 
    ${width - halfBorderWidth},${Math.floor(bevelPercent * width)}
    ${width - halfBorderWidth},${Math.floor(height - bevelPercent * width)}
    ${Math.floor(width - bevelPercent * width)},${height - halfBorderWidth} 
    ${Math.floor(bevelPercent * width)},${height - halfBorderWidth} 
    ${halfBorderWidth},${Math.floor(height - bevelPercent * width)} 
    ${halfBorderWidth},${Math.floor(bevelPercent * width)}
    ${Math.floor(bevelPercent * width)},${halfBorderWidth}
    ${Math.floor(bevelPercent * width) + halfBorderWidth},${halfBorderWidth}`;

    return (
      <View style={{ flex: 1 }}>
        <Svg height="100%" width="100%">
          <Defs>
            <ClipPath id="clip">
              <Polygon
                points={points}
              />
            </ClipPath>
          </Defs>
          <Image
            x={portraitImageBorderStyle.borderWidth}
            y={portraitImageBorderStyle.borderWidth}
            width={width - (2 * portraitImageBorderStyle.borderWidth)}
            height={height - (2 * portraitImageBorderStyle.borderWidth)}
            preserveAspectRatio="xMidYMid slice"
            href={imageUrl}
            clipPath="url(#clip)"
          />
          <Polyline
            points={points}
            stroke={`${portraitStyle.portraitImageBorderStyle.borderColor}`}
            strokeWidth={`${portraitStyle.portraitImageBorderStyle.borderWidth}`}
            fill="transparent"
          />
        </Svg>
      </View>
    );
  }

  return (
    <View style={[portraitStyle.portraitImageBorderStyle, { width, height }]}>
      {children}
    </View>
  );
};

export default PortraitImageBorder;
