import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { Button, Icon, Spinner } from '@ui-kitten/components';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import Slider from 'react-native-slider';

import { Context as DimensionsContext } from '../context/DimensionsContext';
import { Context as CompositesContext } from '../context/CompositesContext';
import CrestZone from '../components/CrestZone';
import Portrait from '../components/Portrait';
import TextAdjuster from '../components/TextAdjuster';
import BorderPicker from '../components/BorderPicker';
import FontPicker from '../components/FontPicker';
import ColorPicker from '../components/ColorPicker';

const BORDER_WIDTH_MIN = 0;
const BORDER_WIDTH_MAX = 30;
const BORDER_RADIUS_MIN = 0;
const BORDER_RADIUS_MAX = 100;

const CHAPTER_TEXT_MIN = 8;
const CHAPTER_TEXT_MAX = 48;
const ORG_TEXT_MIN = 8;
const ORG_TEXT_MAX = 64;
const UNIVERSITY_TEXT_MIN = 8;
const UNIVERSITY_TEXT_MAX = 56;

const CompositeScreen = ({ navigation }) => {
  // We'll probably need to tap into a global composite state
  // Navigation params will also have to pass a composite ID

  const [title, _] = useState(navigation.state.params.title);
  const [compositeHeight, __] = useState(undefined);

  // dimensions
  const dimensions = useContext(DimensionsContext);
  const {
    screenWidth,
    screenHeight,
    isPortrait,
  } = dimensions.state;

  // pull off compositeId & compositeData
  const compositeId = navigation.getParam('compositeId');
  const composite = navigation.getParam('compositeData');

  // composites context
  const { saveCompositeData, state } = useContext(CompositesContext);
  const { compositesUserData: { [compositeId]: compositeUserData } } = state;

  const [compositeData, setCompositeData] = useState({
    data: composite,
    edited: composite,
  });
  const { edited } = compositeData;

  const [edit, setEdit] = useState('Composite');
  const [saveLoading, setSaveLoading] = useState(false);

  useEffect(() => {
    navigation.setParams({ title });
  }, []);
  CompositeScreen.navigationOptions = () => ({
    title: 'Customize',
  });

  const width = isPortrait ? 0.8 * screenWidth : 0.7 * screenHeight;
  const height = isPortrait ? 0.4 * screenWidth : 0.7 * screenHeight;

  return (
    <KeyboardAwareScrollView
      extraScrollHeight={100}
      showsVerticalScrollIndicator={false}
    >
      <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
        <View style={{ flexDirection: isPortrait ? 'column' : 'row', justifyContent: 'center', alignItems: 'center', marginTop: 25 }}>
          <Button
            appearance={edit === 'Composite' ? 'filled' : 'outline'}
            style={{ margin: 5, minWidth: 150 }}
            size="giant"
            onPress={() => setEdit('Composite')}
          >
            Composite
          </Button>
          <Button
            appearance={edit === 'Portrait' ? 'filled' : 'outline'}
            style={{ margin: 5, minWidth: 150 }}
            size="giant"
            onPress={() => setEdit('Portrait')}
          >
            Portrait
          </Button>
        </View>
        {/* Crest or Portrait? */}
        <View style={{ flexDirection: isPortrait ? 'column' : 'row', justifyContent: 'center' }}>
          {edit === 'Composite'
            ? (
              <CrestZone
                compositeBackgroundColor={compositeData.edited.compositeBackgroundColor || '#ffffff'}
                crestZone={compositeData.edited.crestZone}
              />
            )
            : (
              <Portrait
                portraitStyle={compositeData.edited.portraitStyle}
                imageUrl={compositeUserData.imageUrl || 'https://bdsi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png'}
                name={compositeUserData.name}
                height={compositeHeight || height}
                // style={{ transform: [{ scale: 3 }] }}
                positions={compositeUserData.positions}
              />
            )}
        </View>
        {edit === 'Composite'
          ? (
            <View style={{ alignItems: 'center' }}>
              <FontPicker
                width={width}
                label="Crest Font"
                selectedOption={compositeData.edited.crestZone.orgTextStyle.fontFamily}
                onSelect={(newChoice) => setCompositeData({
                  ...compositeData,
                  edited: {
                    ...edited,
                    crestZone: {
                      ...edited.crestZone,
                      orgTextStyle: {
                        ...edited.crestZone.orgTextStyle,
                        fontFamily: newChoice,
                      },
                      chapterTextStyle: {
                        ...edited.crestZone.chapterTextStyle,
                        fontFamily: newChoice,
                      },
                      universityTextStyle: {
                        ...edited.crestZone.universityTextStyle,
                        fontFamily: newChoice,
                      },
                      yearTextStyle: {
                        ...edited.crestZone.yearTextStyle,
                        fontFamily: newChoice,
                      },
                    },
                  },
                })}
              />
              <ColorPicker
                defaultColor={compositeData.edited.compositeBackgroundColor}
                width={width}
                itemSize={40}
                title="Background Color"
                colors={['#4128ff', '#696969', '#7B220E', '#000000', '#ffffff', '#134E0C', '#2E6D55',
                  '#132462', '#351C85', '#E7EA84', '#CF7209', '#F1EBD4']}
                onColorChange={(newColor) => {
                  setCompositeData({
                    ...compositeData,
                    edited: {
                      ...edited,
                      compositeBackgroundColor: newColor,
                    },
                  });
                }}
              />
              <ColorPicker
                defaultColor={compositeData.edited.crestZone.chapterTextStyle.color}
                width={width}
                itemSize={40}
                title="Text Color"
                colors={['#4128ff', '#696969', '#7B220E', '#000000', '#ffffff', '#134E0C', '#2E6D55',
                  '#132462', '#351C85', '#E7EA84', '#CF7209', '#F1EBD4']}
                onColorChange={(newColor) => {
                  setCompositeData({
                    ...compositeData,
                    edited: {
                      ...edited,
                      crestZone: {
                        ...edited.crestZone,
                        chapterTextStyle: {
                          ...edited.crestZone.chapterTextStyle,
                          color: newColor,
                        },
                        orgTextStyle: {
                          ...edited.crestZone.orgTextStyle,
                          color: newColor,
                        },
                        universityTextStyle: {
                          ...edited.crestZone.universityTextStyle,
                          color: newColor,
                        },
                        yearTextStyle: {
                          ...edited.crestZone.yearTextStyle,
                          color: newColor,
                        },
                      },
                      portraitStyle: {
                        ...edited.portraitStyle,
                        portraitNameTextStyle: {
                          ...edited.portraitStyle.portraitNameTextStyle,
                          color: newColor,
                        },
                        portraitPositionTextStyle: {
                          ...edited.portraitStyle.portraitPositionTextStyle,
                          color: newColor,
                        },
                      },
                    },
                  });
                }}
              />
              <TextAdjuster
                label="Organization"
                isTextInput
                width={width}
                sliderValue={compositeData.edited.crestZone.orgTextStyle.fontSize}
                minimumValue={ORG_TEXT_MIN}
                maximumValue={ORG_TEXT_MAX}
                textValue={compositeData.edited.crestZone.orgName}
                onChangeText={(text) => setCompositeData({
                  ...compositeData,
                  edited: {
                    ...edited,
                    crestZone: {
                      ...edited.crestZone,
                      orgName: text,
                    },
                  },
                })}
                onSliderValueChange={(value) => setCompositeData({
                  ...compositeData,
                  edited: {
                    ...edited,
                    crestZone: {
                      ...edited.crestZone,
                      orgTextStyle: {
                        ...edited.crestZone.orgTextStyle,
                        fontSize: value,
                      },
                    },
                  },
                })}
              />
              <TextAdjuster
                label="Chapter"
                isTextInput
                width={width}
                minimumValue={CHAPTER_TEXT_MIN}
                maximumValue={CHAPTER_TEXT_MAX}
                sliderValue={compositeData.edited.crestZone.chapterTextStyle.fontSize}
                textValue={compositeData.edited.crestZone.chapterName}
                onChangeText={(text) => setCompositeData({
                  ...compositeData,
                  edited: {
                    ...edited,
                    crestZone: {
                      ...edited.crestZone,
                      chapterName: text,
                    },
                  },
                })}
                onSliderValueChange={(value) => setCompositeData({
                  ...compositeData,
                  edited: {
                    ...edited,
                    crestZone: {
                      ...edited.crestZone,
                      chapterTextStyle: {
                        ...edited.crestZone.chapterTextStyle,
                        fontSize: value,
                      },
                    },
                  },
                })}
              />
              <TextAdjuster
                label="University"
                isTextInput
                width={width}
                minimumValue={UNIVERSITY_TEXT_MIN}
                maximumValue={UNIVERSITY_TEXT_MAX}
                sliderValue={compositeData.edited.crestZone.universityTextStyle.fontSize}
                textValue={compositeData.edited.crestZone.universityName}
                onChangeText={(text) => () => setCompositeData({
                  ...compositeData,
                  edited: {
                    ...edited,
                    crestZone: {
                      ...edited.crestZone,
                      universityName: text,
                    },
                  },
                })}
                onSliderValueChange={(value) => setCompositeData({
                  ...compositeData,
                  edited: {
                    ...edited,
                    crestZone: {
                      ...edited.crestZone,
                      universityTextStyle: {
                        ...edited.crestZone.universityTextStyle,
                        fontSize: value,
                      },
                    },
                  },
                })}
              />
            </View>
          )
          : null}
        {edit === 'Portrait'
          ? (
            <View style={{ alignItems: 'center' }}>
              <BorderPicker
                width={width}
                portraitStyle={compositeData.edited.portraitStyle}
                initial={compositeData.edited.portraitStyle.portraitImageType}
                onSelect={(newPortraitStyle) => setCompositeData({
                  ...compositeData,
                  edited: {
                    ...edited,
                    portraitStyle: newPortraitStyle,
                  },
                })}
              />
              <ColorPicker
                defaultColor={compositeData.edited.portraitStyle.portraitImageBorderStyle.borderColor}
                width={width}
                itemSize={40}
                title="Border Color"
                colors={['#4128ff', '#696969', '#7B220E', '#000000', '#ffffff', '#134E0C', '#2E6D55',
                  '#132462', '#351C85', '#E7EA84', '#CF7209', '#F1EBD4']}
                onColorChange={(newColor) => {
                  setCompositeData({
                    ...compositeData,
                    edited: {
                      ...edited,
                      portraitStyle: {
                        ...edited.portraitStyle,
                        portraitImageBorderStyle: {
                          ...edited.portraitStyle.portraitImageBorderStyle,
                          borderColor: newColor,
                        },
                      },
                    },
                  });
                }}
              />
              <Text style={styles.label}>Border Width</Text>
              <Slider
                style={{ width }}
                minimumValue={BORDER_WIDTH_MIN}
                maximumValue={BORDER_WIDTH_MAX}
                step={1}
                value={edited.portraitStyle.portraitImageBorderStyle.borderWidth}
                onValueChange={(value) => setCompositeData({
                  ...compositeData,
                  edited: {
                    ...edited,
                    portraitStyle: {
                      ...edited.portraitStyle,
                      portraitImageBorderStyle: {
                        ...edited.portraitStyle.portraitImageBorderStyle,
                        borderWidth: value,
                      },
                    },
                  },
                })}
              />
              {compositeData.edited.portraitStyle.portraitImageType === 'rounded'
                ? (
                  <>
                    <Text style={styles.label}>Border Radius</Text>
                    <Slider
                      style={{ width }}
                      minimumValue={BORDER_RADIUS_MIN}
                      maximumValue={BORDER_RADIUS_MAX}
                      value={edited.portraitStyle.portraitImageBorderStyle.borderRadius}
                      onValueChange={(value) => setCompositeData({
                        ...compositeData,
                        edited: {
                          ...edited,
                          portraitStyle: {
                            ...edited.portraitStyle,
                            portraitImageBorderStyle: {
                              ...edited.portraitStyle.portraitImageBorderStyle,
                              borderRadius: value,
                            },
                          },
                        },
                      })}
                    />
                  </>
                )
                : null}
              <FontPicker
                label="Name Font"
                width={width}
                selectedOption={compositeData.edited.portraitStyle.portraitNameTextStyle.fontFamily}
                onSelect={(newChoice) => {
                  setCompositeData({
                    ...compositeData,
                    edited: {
                      ...edited,
                      portraitStyle: {
                        ...edited.portraitStyle,
                        portraitNameTextStyle: {
                          ...edited.portraitStyle.portraitNameTextStyle,
                          fontFamily: newChoice,
                        },
                      },
                    },
                  });
                }}
              />
              <FontPicker
                label="Position Font"
                width={width}
                selectedOption={compositeData.edited.portraitStyle.portraitPositionTextStyle.fontFamily}
                onSelect={(newChoice) => {
                  setCompositeData({
                    ...compositeData,
                    edited: {
                      ...edited,
                      portraitStyle: {
                        ...edited.portraitStyle,
                        portraitPositionTextStyle: {
                          ...edited.portraitStyle.portraitPositionTextStyle,
                          fontFamily: newChoice,
                        },
                      },
                    },
                  });
                }}
              />
            </View>
          )
          : null}
        <View style={{ flexDirection: 'row', marginVertical: 40 }}>
          {saveLoading
            ? <Spinner />
            : (
              <>
                <Button
                  onPress={() => {
                    setCompositeData({ ...compositeData, edited: compositeData.data });
                    navigation.pop();
                  }}
                  size="large"
                  status="danger"
                  style={styles.button}
                >
                  Cancel
                </Button>
                <Button
                  onPress={async () => {
                    // database update
                    setSaveLoading(true);
                    setCompositeData({ ...compositeData, data: compositeData.edited });
                    await saveCompositeData(compositeId, compositeData.edited);
                    setSaveLoading(false);
                    navigation.pop();
                  }}
                  size="large"
                  style={[styles.button, { backgroundColor: '#3DB92B' }]}
                >
                  Save
                </Button>
              </>
            )}
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  button: {
    alignSelf: 'center',
    borderWidth: 0,
    margin: 10,
    minWidth: 120,
  },
  label: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#575757',
    margin: 5,
    marginLeft: 15,
    alignSelf: 'flex-start',
  },
});

export default CompositeScreen;
