import React from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

const BetaFeedbackForm = () => {
  return (
    <TouchableOpacity
      style={styles.buttonStyle}
      // NOTE: window.open() will not work on anything other than web
      onPress={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLSd-9vtJwD0WojqLqeG9C8O1oFd0EqRPCpcHJgnC-xwVijuLXw/viewform?usp=sf_link', '_blank')}
    >
      <Text style={styles.textStyle}>Feedback</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonStyle: {
    position: 'absolute',
    bottom: 20,
    right: 20,
    width: 80,
    height: 80,
    backgroundColor: 'grey',
    borderRadius: 100,
    alignItems: 'center',
    justifyContent: 'center',
    shadowColor: 'black',
    shadowOpacity: 0.4,
    shadowRadius: 5,
  },
  textStyle: {
    fontSize: 14,
    color: 'white',
  },
});

export default BetaFeedbackForm;
