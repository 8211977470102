import * as firebase from 'firebase';
import 'firebase/firestore';

import createDataContext from './createDataContext';

const UNSUBSCRIBE_LISTENERS = 'unsubscribe_listeners';
const UPDATE_COMPOSITE_DATA = 'update_composite_data';
const UPDATE_LISTENERS = 'update_listeners';
const UPDATE_USER_DATA = 'update_user_data';
const SAVE_USER_DATA = 'save_user_data';
const SAVE_COMPOSITE_DATA = 'save_composite_data';
const LOAD_COMPOSITE_DATA = 'load_composite_data';
const RESET = 'reset';
const SET_ADMIN = 'set_admin';

const DEFAULT_USER_DATA = {
  name: '',
  positions: [],
  imageUrl: '',
  semesters: 0,
  special: false,
  execBoard: false,
};

const compositesReducer = (state, action) => {
  switch (action.type) {
    case UNSUBSCRIBE_LISTENERS:
      state.compositeListeners.forEach((listener) => {
        listener();
      });
      return state;

    case UPDATE_COMPOSITE_DATA:
      return {
        ...state,
        compositesData: {
          ...state.compositesData,
          [action.payload.id]: {
            ...action.payload.newData,
            timestamp: action.payload.timestamp,
          },
        },
      };

    case UPDATE_LISTENERS:
      return {
        ...state,
        compositeListeners: action.payload,
      };

    case UPDATE_USER_DATA:
      return {
        ...state,
        compositesUserData: {
          ...state.compositesUserData,
          [action.payload.id]: action.payload.data,
        },
      };

    case SAVE_USER_DATA:
      return {
        ...state,
        compositesUserData: {
          ...state.compositesUserData,
          [action.payload.compositeId]: {
            ...state.compositesUserData[action.payload.compositeId],
            ...action.payload.data,
          },
        },
      };

    case SAVE_COMPOSITE_DATA:
      return {
        ...state,
        compositesData: {
          ...state.compositesData,
          [action.payload.compositeId]: {
            ...action.payload.newCompositeData,
          },
        },
      };

    case LOAD_COMPOSITE_DATA:
      return {
        ...state,
        compositesData: {
          ...state.compositesData,
          [action.payload.compositeId]: {
            ...action.payload.compositeData,
          },
        },
        compositesUserData: {
          ...state.compositesUserData,
          [action.payload.compositeId]: action.payload.memberData || DEFAULT_USER_DATA,
        },
      };

    case SET_ADMIN:
      return {
        ...state,
        compositesUserData: {
          ...state.compositesUserData,
          [action.payload.compositeId]: {
            ...state.compositesUserData[action.payload.compositeId],
            isAdmin: true,
          },
        },
      };

    case RESET:
      return {
        compositeRefs: [],
        compositesData: {},
        compositesUserData: {},
        compositeListeners: [],
      };

    default:
      return state;
  }
};

const initializeListeners = (dispatch) => (arrayOfRefs) => {
  dispatch({ type: UNSUBSCRIBE_LISTENERS });
  const newListeners = arrayOfRefs.map((docRef) => {
    return docRef.onSnapshot((docSnapshot) => {
      if (docSnapshot.data()) {
        dispatch({
          type: UPDATE_COMPOSITE_DATA,
          payload: {
            id: docRef.id,
            newData: docSnapshot.data(),
            timestamp: docSnapshot._document.version.timestamp.seconds,
          },
        });
      }
    });
  });
  dispatch({ type: UPDATE_LISTENERS, payload: newListeners });
};

const fetchUserData = (dispatch) => (arrayOfRefs, uid) => {
  arrayOfRefs.forEach((docRef) => {
    docRef.collection('members').doc(uid).get()
      .then((memberDoc) => {
        if (memberDoc.exists) {
          dispatch({ type: UPDATE_USER_DATA, payload: { id: docRef.id, data: memberDoc.data() } })
        } else {
          dispatch({
            type: UPDATE_USER_DATA,
            payload: {
              id: docRef.id,
              data: DEFAULT_USER_DATA,
            },
          });
          docRef.collection('members').doc(uid).set({
            ...DEFAULT_USER_DATA,
            user: firebase.firestore().collection('users').doc(uid),
          })
            .catch((e) => console.log(e));
        }
      });

    // TODO: this currently is not compatible with our firebase rules, unsure why
    docRef.collection('admins').doc(uid).get()
      .then((adminDoc) => {
        if (adminDoc.exists) dispatch({ type: SET_ADMIN, payload: { compositeId: docRef.id } });
      })
      .catch((e) => console.log(e));
  });
};

const saveUserData = (dispatch) => async (compositeId, docName, newUserData) => {
  const newData = {
    name: newUserData.name,
    positions: newUserData.positions,
    imageUrl: newUserData.imageUrl,
    execBoard: newUserData.execBoard,
    special: newUserData.special,
    semesters: newUserData.semesters,
  };
  if (newUserData.newPhoto) {
    await fetch(newUserData.imageUrl).then((response) => response.blob())
      .then((imageBlob) => firebase.storage().ref().child(`portraits/${compositeId}/${docName}.jpeg`).put(imageBlob))
      .then(async (snapshot) => {
        await snapshot.ref.getDownloadURL() // gets url to photo
          .then((url) => {
            firebase.firestore().doc(`composites/${compositeId}/members/${docName}`)
              .set({ ...newData, imageUrl: url }, { merge: true });
          });
      })
      .catch((e) => console.log(e));
  } else { // no need to make so many async calls if we don't have a new photo
    await firebase.firestore().doc(`composites/${compositeId}/members/${docName}`)
      .set(newData, { merge: true });
  }
  firebase.firestore().doc(`composites/${compositeId}`)
    .set({ needsNewImage: true }, { merge: true });

  dispatch({ type: SAVE_USER_DATA, payload: { compositeId, data: newData } });
};

// Called from PortraitUploadScreen when user is not logged in
// We'll create a new user for them, & ensure consistent data
const saveNewUserData = () => async (email, uid) => {
  // TODO: TURN THIS INTO A BATCH JOB
  // get the user data, store it away
  const userDoc = await firebase.firestore().collection('users').doc(email).get();
  const userData = await userDoc.data();
  if (!userData) return;
  // iterate thru composites, updating each member to have uid as a key
  await userData.composites.forEach(async (ref) => {
    const memberDoc = await ref.collection('members').doc(email).get();
    const memberData = await memberDoc.data();
    await ref.collection('members').doc(email).delete();
    await ref.collection('members').doc(uid).set(memberData);
  });
  // delete email key user, replace with uid key user
  await firebase.firestore().collection('users').doc(email).delete();
  return firebase.firestore().collection('users').doc(uid).set(userData);
};

// called from UserUploadScreen when an email
const createNewUser = () => async (compositeId, email) => {
  const compositeRef = await firebase.firestore().collection('composites').doc(compositeId);

  await firebase.firestore().collection('users').doc(email).set({
    composites: [compositeRef],
    email,
    emailVerified: false,
  })
    .catch((e) => console.log('creating new user', e));
};

// called when user saves edited styling data on composite screen
const saveCompositeData = (dispatch) => async (compositeId, newCompositeData) => {
  await firebase.firestore().collection('composites').doc(compositeId)
    .set({ ...newCompositeData, needsNewImage: true }, { merge: true });
  dispatch({ type: SAVE_COMPOSITE_DATA, payload: { compositeId, newCompositeData } });
};

// used on UserUploadScreen to load composite data given a composite id from query params
const loadCompositeData = (dispatch) => async (compositeId, uid) => {
  const docRef = await firebase.firestore().collection('composites').doc(compositeId);
  return docRef.get()
    .then(async (doc) => {
      const compositeData = doc.data();
      // if we are given a uid, we dispatch with memberData, else we'll load
      // compositeUserData with default data
      if (uid) {
        await docRef.collection('members').doc(uid).get()
          .then((memberDoc) => dispatch({
            type: LOAD_COMPOSITE_DATA,
            payload: { compositeId, compositeData, memberData: memberDoc.data() },
          }));
      } else {
        dispatch({ type: LOAD_COMPOSITE_DATA, payload: { compositeId, compositeData } });
      }
    })
    .catch((e) => console.log(e));
};

const resetCompositesContext = (dispatch) => () => dispatch({ type: RESET });

export const { Context, Provider } = createDataContext(
  compositesReducer,
  {
    initializeListeners,
    fetchUserData,
    saveUserData,
    saveCompositeData,
    resetCompositesContext,
    loadCompositeData,
    saveNewUserData,
    createNewUser,
  },
  {
    compositeRefs: [],
    compositesData: {},
    compositesUserData: {},
    compositeListeners: [],
  },
);
