import React, { useEffect, useContext } from 'react';
import {
  AsyncStorage,
  StyleSheet,
  View,
  Text,
} from 'react-native';
import { Linking } from 'expo';
import { Spinner } from '@ui-kitten/components';
import firebase from 'firebase';

import { Context as UserContext } from '../context/UserContext';
import { Context as CompositesContext } from '../context/CompositesContext';

const AuthLoadingScreen = (props) => {
  // AsyncStorage.clear();
  const { setUserAuth, resetUserContext } = useContext(UserContext);
  const {
    resetCompositesContext,
    loadCompositeData,
    saveNewUserData,
  } = useContext(CompositesContext);

  useEffect(() => {
    resetUserContext();
    resetCompositesContext();
    bootstrapAsync();
  }, []);

  // Fetch the token from storage then navigate to our appropriate place
  const bootstrapAsync = async () => {
    let userAuth = await AsyncStorage.getItem('userAuth');
    try {
      userAuth = await JSON.parse(userAuth);
      if (userAuth) {
        await setUserAuth(userAuth);
      }
    } catch (e) {
      console.log('error setting user auth', e);
    }

    // get & parse the incoming url
    const url = await Linking.getInitialURL();
    const { queryParams } = Linking.parse(url);

    if (await firebase.auth().isSignInWithEmailLink(url)) {
      // Additional state parameters can also be passed via URL.
      // This can be used to continue the user's intended action before triggering
      // the sign-in operation.
      // Get the email if available. This should be available if the user completes
      // the flow on the same device where they started it.

      // const email = await AsyncStorage.getItem('emailForSignIn').then((e) => console.log(e));

      // The client SDK will parse the code from the link for you.
      await firebase.auth().signInWithEmailLink(queryParams.email, url)
        .then(async (result) => {
          // If the link is to set up a password, go there, else go to the upload screen
          await setUserAuth(result.user);
          await AsyncStorage.setItem('userAuth', JSON.stringify(result.user));
          await saveNewUserData(queryParams.email, result.user.uid);
          await loadCompositeData(queryParams.compositeId, result.user.uid);
          props.navigation.navigate('UserUpload', { compositeId: queryParams.compositeId, auth: true });
        })
        .catch((error) => {
          props.navigation.navigate('Error', { message: error.message });
        });
    } else {
      switch (queryParams.action) {
        case 'upload':
          // go to upload screen w/ composite id
          if (queryParams.compositeId) {
            // check if composite even exists
            // TODO: USER DATA IS LOADED HERE, BUT USERAUTH IS UNDEFINED
            // this means when we get to upload screen and we are auth'd,
            // no data shows up for us for that composite
            userAuth
              ? await loadCompositeData(queryParams.compositeId, userAuth.uid)
              : await loadCompositeData(queryParams.compositeId);
            props.navigation.navigate('UserUpload', { compositeId: queryParams.compositeId, auth: !!userAuth });
          } else {
            props.navigation.navigate('Error', { message: 'This composite does not exist' });
          }
          break;
        case 'shop':
          break;
        default:
          // This will switch to the App screen or Auth screen and this loading
          // screen will be unmounted and thrown away.
          if (userAuth) {
            props.navigation.navigate('App');
          } else {
            props.navigation.navigate('Auth');
          }
      }
    }
  };

  // Render any loading content that you like here
  return (
    <View style={styles.container}>
      <Text style={styles.logo}>Spiffy</Text>
      <Spinner size="giant" status="basic" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    backgroundColor: '#3366ff',
  },
  logo: {
    fontFamily: 'spiffy',
    fontSize: 96,
    margin: 20,
    color: 'white',
  },
});

export default AuthLoadingScreen;
