import React, { useState } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Share, Platform, Clipboard } from 'react-native';
import { Button, Icon, Tooltip } from '@ui-kitten/components';
import { Linking } from 'expo';

import PreviewImage from './PreviewImage';

const CompositePreview = ({ title, navigation, compositeWidth, compositeData, compositeId }) => {
  const [tooltip, setTooltip] = useState(false);

  let shareLink = `${Linking.makeUrl('', { compositeId, action: 'upload' })}`;
  shareLink = `http${shareLink.slice(shareLink.indexOf('://'))}`;

  const onShare = async () => {
    try {
      const result = await Share.share({
        message: `We're using Spiffy for our composite! 
        Go to ${shareLink} to upload your portrait.`,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      console.log(error);
    }
  };

  const copyShareLink = () => {
    Clipboard.setString(shareLink);
    setTooltip(true);
  };

  return (
    <View style={[styles.container, { width: compositeWidth + 40 }]}>
      <TouchableOpacity onPress={() => {
        navigation.navigate('Composite', { title, compositeData, compositeId });
      }}
      >
        <PreviewImage
          width={compositeWidth}
          key={compositeData.timestamp}
          source={{ uri: `${compositeData.previewURL}&reload=${compositeData.timestamp}` }}
          style={{ marginVertical: 10, marginTop: 20 }}
          loading={compositeData.needsNewImage}
        />
        <Text style={styles.title} adjustsFontSizeToFit>{compositeData.crestZone.orgName}</Text>
        <Text style={styles.subtitle} adjustsFontSizeToFit>
          {compositeData.crestZone.universityName}
        </Text>
        <Text style={styles.subtitle} adjustsFontSizeToFit>
          {`${compositeData.crestZone.years.from}-${compositeData.crestZone.years.to}`}
        </Text>
      </TouchableOpacity>
      <View style={{ alignItems: 'stretch', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
        <Button
          icon={(style) => <Icon name="camera" {...style} />}
          // onLayout={({ nativeEvent }) => setButtonWidth(nativeEvent.layout.width)}
          size="small"
          onPress={() => navigation.navigate('PortraitUpload', { compositeId })}
          style={{ margin: 5, width: compositeWidth }}
        >
          Upload Your Portrait
        </Button>
        <Tooltip
          visible={tooltip}
          text={`Copied ${Linking.makeUrl('', { compositeId, action: 'upload' })}`}
          onBackdropPress={() => setTooltip(false)}
        >
          <Button
            icon={(style) => <Icon name="external-link-outline" {...style} />}
            size="small"
            onPress={Platform.OS === 'web' ? copyShareLink : onShare}
            style={{ margin: 5, width: compositeWidth, marginBottom: 20 }}
          >
            Share Upload Link
          </Button>
        </Tooltip>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    // alignSelf: 'center',
    color: '#3366FF',
  },
  subtitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: 'black',
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    marginVertical: 10,
    backgroundColor: 'white',
    borderRadius: 7,
    shadowColor: 'black',
    shadowOpacity: 0.3,
    shadowRadius: 5,
  },
});

export default CompositePreview;
