import React, { useState, useEffect, useContext } from 'react';
import { View, StyleSheet } from 'react-native';
import { Button, Icon } from '@ui-kitten/components';
import Image from 'react-native-scalable-image';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

import { Context as DimensionsContext } from '../context/DimensionsContext';
import { Context as CompositesContext } from '../context/CompositesContext';
import Portrait from '../components/Portrait';

const CompositeScreen = ({ navigation }) => {
  // We'll probably need to tap into a global composite state
  // Navigation params will also have to pass a composite ID

  const [title, _] = useState(navigation.state.params.title);
  const [compositeHeight, setCompositeHeight] = useState(undefined);

  const dimensions = useContext(DimensionsContext);
  const {
    screenWidth,
    screenHeight,
    isPortrait,
  } = dimensions.state;

  const compositeId = navigation.getParam('compositeId');
  const { state } = useContext(CompositesContext);
  const {
    compositesUserData: {
      [compositeId]: compositeUserData,
    },
    compositesData: {
      [compositeId]: compositeData,
    },
  } = state;

  const composite = navigation.getParam('compositeData');

  useEffect(() => {
    navigation.setParams({ title });
  }, []);

  CompositeScreen.navigationOptions = () => ({
    title: navigation.state.params.title,
    // headerRight: () => (
    //   compositeUserData.isAdmin
    //     ? (
    //       <TouchableOpacity style={{ marginRight: 15 }} onPress={() => console.log('go to finish screen')}>
    //         <Text style={{ color: 'white', fontSize: 18 }}>Finish</Text>
    //       </TouchableOpacity>
    //     )
    //     : null
    // ),
  });

  const width = isPortrait ? 0.8 * screenWidth : 0.7 * screenHeight;
  const height = isPortrait ? 0.4 * screenWidth : 0.7 * screenHeight;

  return (
    <KeyboardAwareScrollView
      extraScrollHeight={100}
      showsVerticalScrollIndicator={false}
    >
      <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1 }}>
        <View style={{ flexDirection: isPortrait ? 'column' : 'row', justifyContent: 'center' }}>
          <View style={{ alignItems: 'center' }}>
            <Image
              onLayout={({ nativeEvent }) => {
                setCompositeHeight(nativeEvent.layout.height);
              }}
              style={{ marginTop: 25 }}
              width={width}
              source={{ uri: `${compositeData.previewURL}&reload=${compositeData.timestamp}` || 'https://www.publicdomainpictures.net/download-picture.php?id=28763&check=40d0c7d2a335794339b3a2023655e58f' }}
              resizeMode="contain"
            />
            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
              {/* <Button
                    icon={(style) => <Icon name="people-outline" {...style} />}
                    size="large"
                    style={{ margin: 15 }}
                    onPress={() => navigation.navigate('Roster', { title: navigation.state.params.title })}
                  >
                    Roster
                  </Button> */}
              {compositeUserData.isAdmin
                ? (
                  <Button // Only render if the user is an admin for this composite
                    icon={(style) => <Icon name="edit-outline" {...style} />}
                    size="large"
                    style={{ margin: 15 }}
                    onPress={() => navigation.navigate('Edit', { compositeData, compositeId })}
                  // onPress={() => setEditMode(!editMode)}
                  >
                    Customize
                  </Button>
                )
                : null}
            </View>
          </View>

          <View style={{ alignItems: 'center' }}>
            <Portrait
              portraitStyle={compositeData.portraitStyle}
              imageUrl={compositeUserData.imageUrl || 'https://bdsi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png'}
              name={compositeUserData.name}
              height={compositeHeight || height}
              // style={{ transform: [{ scale: 3 }] }}
              positions={compositeUserData.positions}
            />
            <Button
              icon={(style) => <Icon name="person-outline" {...style} />}
              size="large"
              style={{ margin: 15, marginBottom: 25 }}
              onPress={() => navigation.navigate('PortraitUpload', { compositeId })}
            >
              My Portrait
            </Button>
          </View>
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({});

export default CompositeScreen;
