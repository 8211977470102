import React, { useState } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { Select } from '@ui-kitten/components';

const fonts = [
  { text: 'Spiffy', textStyle: { fontFamily: 'spiffy' }, value: 'spiffy' },
  { text: 'Courgette', textStyle: { fontFamily: 'courgette' }, value: 'courgette' },
  { text: 'Open Sans', textStyle: { fontFamily: 'open-sans' }, value: 'open-sans' },
  { text: 'Pirata One', textStyle: { fontFamily: 'pirata-one' }, value: 'pirata-one' },
  { text: 'Teko', textStyle: { fontFamily: 'teko' }, value: 'teko' },
  { text: 'Lobster', textStyle: { fontFamily: 'lobster' }, value: 'lobster' },
  { text: 'Baskerville', textStyle: { fontFamily: 'baskerville' }, value: 'baskerville' },
  { text: 'Canterbury', textStyle: { fontFamily: 'canterbury' }, value: 'canterbury' },
  { text: 'English Towne', textStyle: { fontFamily: 'english-towne' }, value: 'english-towne' },
  { text: 'Old English Five', textStyle: { fontFamily: 'old-english-five' }, value: 'old-english-five' },
  { text: 'Gelasio', textStyle: { fontFamily: 'gelasio' }, value: 'gelasio' },
  { text: 'Source Sans Pro', textStyle: { fontFamily: 'source-sans-pro' }, value: 'source-sans-pro' },
  { text: 'Courier Prime', textStyle: { fontFamily: 'courier-prime' }, value: 'courier-prime' },
  { text: 'Stoke', textStyle: { fontFamily: 'stoke' }, value: 'stoke' },
  { text: 'Acme', textStyle: { fontFamily: 'acme' }, value: 'acme' },
  { text: 'Marcellus SC', textStyle: { fontFamily: 'marcellus-sc' }, value: 'marcellus-sc' },
];

const FontPicker = ({ selectedOption, onSelect, width, label }) => {
  const [startingFont] = fonts.filter((font) => font.value === selectedOption);
  const [selected, setSelected] = useState(startingFont);
  return (
    <View style={{ width, margin: 10 }}>
      <Text style={styles.label}>{label}</Text>
      <Select
        data={fonts}
        textStyle={selected.textStyle || undefined}
        selectedOption={selected}
        onSelect={(newChoice) => {
          setSelected(newChoice);
          onSelect(newChoice.textStyle.fontFamily);
        }}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  label: {
    fontSize: 20,
    color: '#575757',
    fontWeight: 'bold',
    margin: 5,
  },
});

export default FontPicker;
