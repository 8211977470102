import React from 'react';
import { View, Text } from 'react-native';

// We'll take in a composite Id from navigation params
const RosterScreen = ({ navigation }) => {
  RosterScreen.navigationOptions = () => ({
    title: `${navigation.state.params.title} Roster`,
  });

  return (
    <View>
      <Text>Roster screen</Text>
    </View>
  );
};

export default RosterScreen;
