import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet } from 'react-native';

const colorCodes = {
  'Satin Black': '#202020',
  'Gloss Black': '#000000',
  'Satin Silver': '#d9d9d9',
  'Gloss Silver': '#bebebe',
  'Satin Gold': '#ecad01',
  'Gloss Gold': '#c99507',
  Wheat: '#e6d4b4',
  Blue: 'blue',
  Red: 'red',
  Green: 'green',
  Gold: '#c99507',
  Silver: '#d9d9d9',
  Cherry: '#440e04',
  Black: '#000000',
  Merlot: '#441d04',
  'Black Grain': '#181818',
  Coffee: '#382104',
  'Rustic Grey': '#818181',
  Hazel: '#b57729',
  Russet: '#784607',
  White: '#ffffff',
  Ivory: '#fff2e1',
  Sand: '#d8b78f',
  Umber: '#784607',
  Cocoa: '#382105',
  'Hot Pink': '#bb0fda',
};

const ColorPicker = (props) => {
  const { defaultColor, colors, itemSize, onColorChange, title, width } = props;

  const [selectedColor, setSelectedColor] = useState(defaultColor);

  return (
    <View style={{ width }}>
      <Text style={styles.titleStyle}>{title}</Text>
      <View style={{ justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap', flex: 1 }}>
        {[...new Set(colors)].map((color) => {
          return (
            <TouchableOpacity
              onPress={() => {
                setSelectedColor(color);
                onColorChange(color);
              }}
              key={color}
            >
              <View style={{
                height: itemSize,
                width: itemSize,
                borderRadius: itemSize,
                marginHorizontal: 3,
                marginVertical: 8,
                backgroundColor: colorCodes[color] || color,
                borderWidth: color === selectedColor ? 2 : 1,
                borderColor: color === selectedColor ? '#48629B' : 'gray',
              }}
              />
            </TouchableOpacity>
          );
        })}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  titleStyle: {
    fontSize: 20,
    color: '#575757',
    fontWeight: 'bold',
    margin: 5,
  },
});

export default ColorPicker;
