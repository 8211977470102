import React, { useContext, useEffect, useState } from 'react';
import { View, StyleSheet, TouchableOpacity, AsyncStorage, Dimensions, Text } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { Icon, Button, Modal, Input } from '@ui-kitten/components';
import { Linking } from 'expo';

import { Context as UserContext } from '../context/UserContext';
import { Context as DimensionsContext } from '../context/DimensionsContext';
import { Context as CompositesContext } from '../context/CompositesContext';
import CompositePreview from '../components/CompositePreview';

const UserHomeScreen = ({ navigation }) => {
  // Use user context
  const userStore = useContext(UserContext);
  const {
    resetUserContext,
    setOnboarded,
    state: { userData, userAuth, joinCompositeError },
    joinComposite,
  } = userStore;
  const { composites, onboarded } = userData;

  if (!userAuth) navigation.navigate('Auth');

  // Use composites context
  const compositesStore = useContext(CompositesContext);
  const {
    fetchUserData,
    initializeListeners,
    resetCompositesContext,
    state: { compositesData },
  } = compositesStore;

  // Initialize listeners to update composite data when database changes
  useEffect(() => {
    if (userAuth) {
      initializeListeners(composites);
      fetchUserData(composites, userAuth.uid);
    }
  }, [composites.length]);

  // Use dimensions context & mount new listeners
  const dimensions = useContext(DimensionsContext);
  const { screenWidth, isPortrait } = dimensions.state;
  const { updateDimensions } = dimensions;
  useEffect(() => {
    Dimensions.addEventListener('change', updateDimensions);
    return () => Dimensions.removeEventListener('change', updateDimensions);
  }, []);

  // Handle logout
  const onLogout = async () => {
    resetUserContext();
    resetCompositesContext();
    await AsyncStorage.clear();
    navigation.navigate('Auth');
  };

  // Logout modal
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const logoutModal = () => {
    return (
      <View style={styles.modalView}>
        <Text style={styles.modalText}>Logout?</Text>
        <View style={{ flexDirection: 'row' }}>
          <Button
            onPress={() => setLogoutModalVisible(false)}
            status="danger"
            size="large"
            style={{ margin: 10 }}
          >
            Cancel
          </Button>
          <Button
            onPress={async () => {
              await setLogoutModalVisible(false);
              onLogout();
            }}
            status="primary"
            size="large"
            style={{ margin: 10 }}
          >
            Logout
          </Button>
        </View>
      </View>
    );
  };

  // Navigation options for this screen
  UserHomeScreen.navigationOptions = () => ({
    title: 'My Composites',
    headerLeft: () => (
      <TouchableOpacity style={{ marginLeft: 15 }} onPress={() => navigation.navigate('CreateComposite')}>
        <Icon name="plus-outline" fill="black" width={32} height={32} />
      </TouchableOpacity>
    ),
  });
  useEffect(() => {
    navigation.setParams({ title: 'My Composites' });
  }, []);

  // Probably pre-cache info about each composite the user is a member of

  // Handle onboarding
  if (!onboarded && userAuth) {
    navigation.navigate('Onboarding');
    setOnboarded(userAuth.uid, true);
  }

  // Handle joining a composite
  const [joinControl, setJoinControl] = useState({
    showJoin: false,
    joinText: '',
    joinError: '',
  });
  const onJoinComposite = () => {
    const { queryParams } = Linking.parse(joinControl.joinText);
    console.log(queryParams);
    queryParams.compositeId
      ? joinComposite(queryParams.compositeId, userAuth.uid, composites)
      : setJoinControl({ ...joinControl, joinError: 'That doesn\'t seem to be a link' });
  };

  const compositeWidth = isPortrait ? 0.7 * screenWidth : 0.35 * screenWidth;

  return userAuth ? (
    <View style={{ flex: 1 }}>
      <Modal
        allowBackdrop
        backdropStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
        onBackdropPress={() => setLogoutModalVisible(!logoutModalVisible)}
        visible={logoutModalVisible}
      >
        {logoutModal()}
      </Modal>
      <ScrollView showsVerticalScrollIndicator={false}>
        {/* <View style={{ flex: 1 }}> */}
        {Object.keys(compositesData).map((compositeId) => (
          <CompositePreview
            key={compositeId}
            title={`${compositesData[compositeId].crestZone.orgName} ${compositesData[compositeId].crestZone.years.from}-${compositesData[compositeId].crestZone.years.to}`} // Update to be a real title
            navigation={navigation}
            compositeWidth={compositeWidth}
            compositeData={compositesData[compositeId]}
            compositeId={compositeId}
          />
        ))}
        <Button
          icon={(style) => <Icon name="plus-outline" {...style} />}
          onPress={() => navigation.navigate('CreateComposite')}
          size="giant"
          style={[styles.button, { width: compositeWidth, marginBottom: 20, marginTop: 30 }]}
        >
          Create New Composite
        </Button>
        {!joinControl.showJoin ? (
          <Button
            icon={(style) => <Icon name="diagonal-arrow-right-up" {...style} />}
            onPress={() => setJoinControl({ ...joinControl, showJoin: true })}
            size="giant"
            style={[styles.button, { width: compositeWidth, marginBottom: 20 }]}
          >
            Join a Composite
          </Button>
        )
          : (
            <View style={{
              marginBottom: 20,
              flexDirection: 'row',
              width: compositeWidth,
              alignSelf: 'center',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            >
              <Input
                style={{ width: compositeWidth - 90 }}
                placeholder="Paste join link"
                status={joinControl.joinError || joinCompositeError ? 'danger' : 'primary'}
                caption={joinControl.joinError || joinCompositeError}
                onChangeText={(text) => setJoinControl({
                  ...joinControl,
                  joinText: text,
                })}
              />
              <Button
                style={{ width: 80, marginLeft: 10 }}
                onPress={onJoinComposite}
              >
                Join
              </Button>
            </View>
          )}
        <Button
          onPress={() => setLogoutModalVisible(!logoutModalVisible)}
          appearance="ghost"
          style={{ marginBottom: 40, width: 200, alignSelf: 'center' }}
        >
          Logout
        </Button>
        {/* </View> */}
      </ScrollView>
    </View>

  ) : null;
};

const styles = StyleSheet.create({
  button: {
    alignSelf: 'center',
    backgroundColor: '#3366ff',
  },
  modalView: {
    borderRadius: 4,
    backgroundColor: 'white',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  modalText: {
    fontSize: 24,
    fontWeight: 'bold',
    margin: 10,
  },
});

export default UserHomeScreen;
