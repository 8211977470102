import React, { useState, useContext, useEffect } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, Image, Dimensions } from 'react-native';
import { Icon, Button, Input, Spinner, Select } from '@ui-kitten/components';
import * as ImagePicker from 'expo-image-picker';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import firebase from 'firebase';
import * as ImageManipulator from 'expo-image-manipulator';

import { Context as UserContext } from '../context/UserContext';
import { Context as DimensionsContext } from '../context/DimensionsContext';
import { Context as CompositesContext } from '../context/CompositesContext';
import Portrait from '../components/Portrait';
import PreviewImage from '../components/PreviewImage';

const MAX_POSITIONS = 2;

const isValidEmail = (email) => {
  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  return emailFormat.test(email);
};

// We'll receive a user ID & composite ID from navigation prop
const UserUploadScreen = ({ navigation }) => {
  const compositeId = navigation.getParam('compositeId');

  // Dimensions
  const dimensions = useContext(DimensionsContext);
  const { screenWidth, isPortrait } = dimensions.state;
  // Mount dimensions listeners
  useEffect(() => {
    Dimensions.addEventListener('change', dimensions.updateDimensions);
    return () => Dimensions.removeEventListener('change', dimensions.updateDimensions);
  }, []);

  // Composites context
  const compositesStore = useContext(CompositesContext);
  const { saveUserData, createNewUser } = compositesStore;
  const {
    compositesData: { [compositeId]: compositeData },
    compositesUserData: { [compositeId]: compositeUserData },
  } = compositesStore.state;

  // ensure title at navigation bar looks good
  useEffect(() => {
    navigation.setParams({ title: `${compositeData.crestZone.orgName} ${compositeData.crestZone.years.from}-${compositeData.crestZone.years.to}` });
  }, []);

  const [userPortraitData, setUserPortraitData] = useState({
    name: compositeUserData.name,
    positions: compositeUserData.positions,
    imageUrl: compositeUserData.imageUrl,
    seniority: undefined,
    newPhoto: false,
    execBoard: false,
    special: false,
    semesters: compositeUserData.semesters,
  });

  const updatePortrait = async (image) => {
    await ImageManipulator.manipulateAsync(image.uri, [])
      .then(async (img) => {
        if (img.height < img.width) {
          await ImageManipulator.manipulateAsync(image.uri, [{ rotate: -90 }])
            .then((final) => {
              setUserPortraitData({
                ...userPortraitData,
                imageUrl: final.uri,
                newPhoto: true,
              });
            });
        } else {
          setUserPortraitData({
            ...userPortraitData,
            imageUrl: img.uri,
            newPhoto: true,
          });
        }
      });
  };

  const onUploadPress = async () => {
    const { granted } = await ImagePicker.requestCameraRollPermissionsAsync();
    if (granted) {
      const response = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        quality: 1,
        exif: true,
        base64: true,
        allowsEditing: true,
      });
      if (!response.cancelled) updatePortrait(response);
    }
  };

  // const onTakePhotoPress = async () => {
  //   const { cancelled } = await ImagePicker.requestCameraPermissionsAsync();
  //   if (!cancelled) {
  //     const response = await ImagePicker.launchCameraAsync({
  //       quality: 1,
  //     });
  //     if (!response.cancelled) {
  //       setUserPortraitData({
  //         ...userPortraitData,
  //         imageUrl: response.uri,
  //         newPhoto: true,
  //       });
  //     }
  //   }
  // };

  // User store & saving user data
  const userStore = useContext(UserContext);
  const [emailLocked, setEmailLocked] = useState(!!userStore.state.userAuth);
  const [saveLoading, setSaveLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState(userStore.state.userData.email || userStore.state.userAuth ? userStore.state.userAuth.email : '');
  const [emailError, setEmailError] = useState('');
  const [resetEmailSent, setResetEmailSent] = useState({
    showing: false,
    infoText: '',
  });
  const [infoText, setInfoText] = useState('Thanks! You\'ve been sent an email with a link to edit your portrait information if needed.');

  const onPasswordReset = async (email) => {
    isValidEmail(email)
      ? userStore.sendResetPasswordEmail(email).then(() => {
        setResetEmailSent({ ...resetEmailSent, showing: true });
        setInfoText('A a link to reset your password has been sent to your email.');
      })
      : setInfoText('Invalid Email');
  };

  const onSavePress = async () => {
    setSaveLoading(true);
    setEmailLocked(true);
    if (isValidEmail(email)) {
      const actionCodeSettings = {
        // URL you want to redirect back to. The domain (www.example.com) for this
        // URL must be whitelisted in the Firebase Console.
        url: `http://localhost:19006?action=upload&compositeId=${compositeId}&email=${email}`,
        // This must be true.
        handleCodeInApp: true,
      };

      firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings);

      // check if a user w/ that email already exists in the database
      let emailUsed = false;
      const compositeRef = await firebase.firestore().collection('composites').doc(compositeId);
      const userSnapshot = await firebase.firestore().collection('users').where('email', '==', email).get().catch((e) => console.log('querying users', e));
      userSnapshot.forEach((doc) => {
        const data = doc.data();
        data.composites.forEach((c) => { if (c.id === compositeId) emailUsed = true; });
      });

      if (!emailUsed) {
        // check for user in users collection
        // if in there, update current doc, otherwise createNewUser
        const query = await firebase.firestore().collection('users').where('email', '==', email).get()
          .then(async (snapshot) => {
            if (snapshot.empty) {
              await createNewUser(compositeId, email);
            } else {
              snapshot.forEach(async (doc) => {
                await doc.ref.update({
                  composites: firebase.firestore.FieldValue.arrayUnion(compositeRef),
                });
              });
            }
          }).catch((e) => console.log('email exists query', e));

        if (!userStore.state.userAuth) {
          await saveUserData(compositeId, email, userPortraitData);
          setSubmitted(true);
        } else {
          await saveUserData(compositeId, userStore.state.userAuth.uid, userPortraitData);
          setSubmitted(true);
        }
      } else if (userStore.state.userAuth) {
        await saveUserData(compositeId, userStore.state.userAuth.uid, userPortraitData);
        setSubmitted(true);
      } else {
        setEmailLocked(false);
        setEmailError('This email is already in use for this composite. If it is your email, login or check your email for an edit link.');
      }
    } else {
      setEmailLocked(false);
      setEmailError('Invalid email');
    }
    setSaveLoading(false);
  };

  const [semesters, setSemesters] = useState({
    text: userPortraitData.semesters === 10 ? '10+' : userPortraitData.semesters.toString(),
    value: userPortraitData.semesters,
  });
  const [buttonWidth, setButtonWidth] = useState(null);

  const portraitWidth = isPortrait ? 0.6 * screenWidth : 0.25 * screenWidth;
  const compositeWidth = isPortrait ? 0.8 * screenWidth : 0.6 * screenWidth;
  const inputWidth = isPortrait ? 0.8 * screenWidth : 0.4 * screenWidth;
  console.log(compositeWidth);

  return (
    <KeyboardAwareScrollView
      extraScrollHeight={100}
      showsVerticalScrollIndicator={false}
      contentContainerStyle={{ flex: 1 }}
    >
      <View style={{ alignItems: 'center', flex: 1 }}>
        <PreviewImage
          width={compositeWidth}
          key={compositeData.timestamp}
          source={{ uri: `${compositeData.previewURL}&reload=${compositeData.timestamp}` || 'https://www.publicdomainpictures.net/download-picture.php?id=28763&check=40d0c7d2a335794339b3a2023655e58f' }}
          style={{ marginVertical: 20 }}
          loading={compositeData.needsNewImage}
        />
        {/* <Image
        source={{ uri: compositeData.previewURL }}
        width={compositeWidth}
        height={250}
        style={{ borderWidth: 1, alignSelf: 'center' }}
      /> */}
        <View style={{ justifyContent: 'space-evenly', flex: 1, flexDirection: isPortrait ? 'column' : 'row' }}>
          <View style={{ alignSelf: isPortrait ? 'center' : undefined }}>
            <Portrait
              portraitStyle={compositeData.portraitStyle}
              name={userPortraitData.name}
              positions={userPortraitData.positions}
              height={(portraitWidth * 5) / 3}
              imageUrl={userPortraitData.imageUrl || 'https://bdsi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png'}
            />
          </View>
          <View style={{ flex: 1, alignItems: 'center' }}>
            {/* <Button
              icon={(style) => <Icon name="camera" {...style} />}
              style={{ margin: 8, width: buttonWidth }}
              status="primary"
              onPress={onTakePhotoPress}
            >
              Take Photo
            </Button> */}
            <Text style={styles.instructionText}>Email</Text>
            <Input
              style={[styles.input, { width: inputWidth }]}
              status={emailError ? 'danger' : 'primary'}
              disabled={emailLocked}
              placeholder="Your email"
              value={email}
              caption={emailError}
              onChangeText={(text) => setEmail(text)}
            />
            <Text style={styles.instructionText}>Name</Text>
            <Input
              style={[styles.input, { width: inputWidth }]}
              status="primary"
              placeholder="Your Name"
              value={userPortraitData.name}
              onChangeText={(text) => setUserPortraitData({ ...userPortraitData, name: text })}
            />
            <Text style={styles.instructionText}>Position(s)</Text>
            {userPortraitData.positions.map((position, i) => (
              <View key={i} style={{ flexDirection: 'row', alignItems: 'center' }}>
                <Input
                  style={[styles.input, { width: inputWidth - 36 }]}
                  status="primary"
                  placeholder="Your Position"
                  value={userPortraitData.positions[i]}
                  onChangeText={(text) => {
                    const { positions } = userPortraitData;
                    positions[i] = text;
                    setUserPortraitData({ ...userPortraitData, positions });
                  }}
                />
                <TouchableOpacity onPress={() => setUserPortraitData({
                  ...userPortraitData,
                  positions: userPortraitData.positions.filter((pos, index) => index !== i),
                })}
                >
                  <Icon name="close-outline" style={{ width: 36, height: 36 }} />
                </TouchableOpacity>
              </View>
            ))}
            {userPortraitData.positions.length > 0
              ? (
                <View style={{ flexDirection: 'row' }}>
                  <Button
                    style={{ width: (inputWidth / 2) - 8, margin: 8 }}
                    size="small"
                    appearance={userPortraitData.execBoard ? 'filled' : 'outline'}
                    onPress={() => setUserPortraitData({ ...userPortraitData, execBoard: !userPortraitData.execBoard })}
                  >
                    Exec
                </Button>
                  <Button
                    style={{ width: (inputWidth / 2) - 8, margin: 8 }}
                    size="small"
                    appearance={userPortraitData.special ? 'filled' : 'outline'}
                    onPress={() => setUserPortraitData({ ...userPortraitData, special: !userPortraitData.special })}
                  >
                    Special
                </Button>
                </View>
              )
              : null}
            {userPortraitData.positions.length < MAX_POSITIONS
              ? (
                <View style={{ flexDirection: 'column' }}>
                  <Button
                    icon={(style) => <Icon name="plus-outline" {...style} />}
                    style={{ height: 40, width: inputWidth, margin: 8 }}
                    onPress={() => setUserPortraitData({ ...userPortraitData, positions: [...userPortraitData.positions, ''] })}
                  >
                    Add New
                </Button>
                </View>
              )
              : null}
            <Text style={styles.instructionText}>Semesters</Text>
            <Select
              style={{ margin: 8, width: inputWidth }}
              data={[{ text: '0', value: 0 }, { text: '1', value: 1 }, { text: '2', value: 2 }, { text: '3', value: 3 },
              { text: '4', value: 4 }, { text: '5', value: 5 }, { text: '6', value: 6 }, { text: '7', value: 7 },
              { text: '8', value: 8 }, { text: '9', value: 9 }, { text: '10+', value: 10 }]}
              selectedOption={semesters}
              onSelect={(option) => {
                setSemesters(option);
                setUserPortraitData({ ...userPortraitData, semesters: option.value });
              }}
            />
            <Button
              icon={(style) => <Icon name="upload" {...style} />}
              onLayout={({ nativeEvent }) => setButtonWidth(nativeEvent.layout.width)}
              style={{ margin: 8, width: inputWidth }}
              status="primary"
              onPress={onUploadPress}
            >
              Upload Photo
            </Button>
            <View style={{ alignItems: 'center', justifyContent: 'center', marginBottom: 40, marginTop: 10 }}>
              {/* eslint-disable-next-line no-nested-ternary */}
              {submitted
                ? (
                  <View style={{ width: inputWidth, alignItems: 'center' }}>
                    <Text style={{ fontSize: 20, flexWrap: 'wrap', textAlign: 'center' }}>
                      {infoText}
                    </Text>
                    {userStore.state.userAuth
                      ? (
                        <>
                          {resetEmailSent.showing
                            ? null
                            : (
                              <Button
                                onPress={() => onPasswordReset(email)}
                                status="success"
                                style={[styles.button, { width: inputWidth }]}
                              >
                                Set up a Password
                            </Button>
                            )}
                        </>
                      ) : null}
                  </View>
                ) : saveLoading
                  ? <Spinner status="success" />
                  : (
                    <Button
                      onPress={onSavePress}
                      size="giant"
                      style={[styles.button, { width: 0.4 * screenWidth }]}
                    >
                      Save
                  </Button>
                  )}
            </View>
          </View>
        </View>
      </View>
    </KeyboardAwareScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  instructionText: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#575757',
    marginTop: 8,
    marginLeft: 8,
  },
  input: {
    margin: 8,
  },
  positionView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: 8,
    // borderWidth: 2,
    borderColor: '#3366FF',
    borderRadius: 4,
    backgroundColor: 'white',
  },
  button: {
    alignSelf: 'center',
    backgroundColor: '#3DB92B',
    borderWidth: 0,
    margin: 10,
  },
  positionText: {
    fontSize: 18,
    margin: 8,
    color: '#575757',
    fontWeight: 'bold',
    alignSelf: 'center',
  },
});

export default UserUploadScreen;
