import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';
import OnLayout from 'react-native-on-layout';
import PortraitImageBorder from './PortraitImageBorder';

const Portrait = ({ name, positions, imageUrl, portraitStyle, height, style }) => {
  return (
    <View style={[styles.container, style, { width: (height * 3) / 5, height }]}>
      <OnLayout style={{ flex: 1 }}>
        {({ width, height }) => (
          <PortraitImageBorder
            imageUrl={imageUrl}
            width={width}
            height={height}
            portraitStyle={portraitStyle}
          >
            <Image
              source={{ uri: imageUrl }}
              style={portraitStyle.portraitImageStyle}
              resizeMode="cover"
            />
          </PortraitImageBorder>
        )}
      </OnLayout>
      <View style={styles.textBlock}>
        <Text
          numberOfLines={1}
          adjustsFontSizeToFit
          style={portraitStyle.portraitNameTextStyle}
        >
          {name}
        </Text>
        {positions ? positions.map((position) => (
          <Text
            key={positions.indexOf(position)}
            numberOfLines={1}
            adjustsFontSizeToFit // doesn't work on web
            style={portraitStyle.portraitPositionTextStyle}
          >
            {position}
          </Text>
        )) : null}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 5,
    // flex: 1,
    marginTop: 25,
  },
  textBlock: {
    textAlign: 'center',
  },
});

export default Portrait;
