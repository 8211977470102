import React, { useState } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';

import Portrait from './Portrait';

const displayPortraitStyle = {
  bevelPercent: 0,
  portraitImageBorderStyle: {
    borderColor: '#4128ff',
    borderRadius: 0,
    borderWidth: 6,
    flex: 1,
    overflow: 'hidden',
    padding: 1,
  },
  portraitImageStyle: {
    flex: 1,
  },
  portraitNameTextStyle: {
    color: '#000',
    fontFamily: 'open-sans',
    fontSize: 12,
    textAlign: 'center',
  },
  portraitPositionTextStyle: {
    color: '#000',
    fontFamily: 'open-sans',
    fontSize: 10,
    textAlign: 'center',
  },
};

const BorderPicker = ({ onSelect, width, portraitStyle, initial }) => {
  const portraitWidth = 0.2 * width;
  const portraitHeight = (portraitWidth * 5) / 3;

  const [chosen, setChosen] = useState(initial);

  return (
    <View style={styles.container}>
      <TouchableOpacity onPress={() => {
        setChosen('rectangle');
        onSelect({
          ...portraitStyle,
          portraitImageType: 'rectangle',
          portraitImageBorderStyle: {
            ...portraitStyle.portraitImageBorderStyle,
            borderRadius: 0,
          },
        });
        // onSelect(newPortraitStyle);
      }}
      >
        <Portrait
          portraitStyle={{ ...displayPortraitStyle, portraitImageType: 'rectangle' }}
          height={chosen === 'rectangle' ? portraitHeight + 30 : portraitHeight}
          selectedPhoto="https://bdsi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png"
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => {
        setChosen('oval');
        onSelect({
          ...portraitStyle,
          portraitImageType: 'oval',
        });
        // onSelect(newPortraitStyle);
      }}
      >
        <Portrait
          portraitStyle={{ ...displayPortraitStyle, portraitImageType: 'oval' }}
          height={chosen === 'oval' ? portraitHeight + 30 : portraitHeight}
          selectedPhoto="https://bdsi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png"
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => {
        setChosen('beveled');
        onSelect({
          ...portraitStyle,
          portraitImageType: 'beveled',
          bevelPercent: 0.25,
        });
        // onSelect(newPortraitStyle);
      }}
      >
        <Portrait
          portraitStyle={{ ...displayPortraitStyle, portraitImageType: 'beveled', bevelPercent: 0.25 }}
          height={chosen === 'beveled' ? portraitHeight + 30 : portraitHeight}
          selectedPhoto="https://bdsi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png"
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={() => {
        setChosen('rounded');
        onSelect({
          ...portraitStyle,
          portraitImageType: 'rounded',
          portraitImageBorderStyle: {
            ...portraitStyle.portraitImageBorderStyle,
            borderRadius: 20,
          },
        });
        // onSelect(newPortraitStyle);
      }}
      >
        <Portrait
          portraitStyle={{ ...displayPortraitStyle, portraitImageType: 'rounded', portraitImageBorderStyle: { ...displayPortraitStyle.portraitImageBorderStyle, borderRadius: 20 } }}
          height={chosen === 'rounded' ? portraitHeight + 30 : portraitHeight}
          selectedPhoto="https://bdsi.com/wp-content/plugins/all-in-one-seo-pack/images/default-user-image.png"
        />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

});

export default BorderPicker;
