import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { Input } from '@ui-kitten/components';
import Slider from 'react-native-slider';

const TextAdjuster = ({
  label,
  textValue,
  maximumValue,
  minimumValue,
  onChangeText,
  width,
  isTextInput,
  sliderValue,
  onSliderValueChange,
}) => (
  <View style={{ width, margin: 10 }}>
    {isTextInput ? <Text style={styles.label}>{label}</Text> : null}
    <View style={{ flexDirection: 'row' }}>
      {isTextInput
        ? <Input value={textValue} onChangeText={onChangeText} style={{ flex: 1 }} />
        : null}
    </View>
    <Slider
      minimumValue={minimumValue}
      maximumValue={maximumValue}
      onValueChange={onSliderValueChange}
      value={sliderValue}
      step={1}
    />
  </View>
);

const styles = StyleSheet.create({
  container: {
    // alignItems: 'center',
    // justifyContent: 'center',
  },
  label: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#575757',
    margin: 5,
  },
  number: {
    fontSize: 30,
    color: 'white',
    paddingHorizontal: 5,
  },
  numberView: {
    width: 50,
    height: 50,
    backgroundColor: '#3366FF',
    marginHorizontal: 10,
    borderRadius: 4,
    flex: 1,
  },
});

export default TextAdjuster;
