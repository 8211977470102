import React from 'react';
import { View } from 'react-native';
import Image from 'react-native-scalable-image';
import { Spinner } from '@ui-kitten/components';

const PreviewImage = ({ source, width, height, style, onLayout, loading }) => {
  return loading
    ? (
      <View style={{ width, height: height || width * 0.8, alignItems: 'center', justifyContent: 'center' }}>
        <Spinner />
      </View>
    ) : (
      <Image
        onLayout={onLayout}
        style={style}
        width={width}
        // height={height || undefined}
        source={source}
        resizeMode="contain"
      />
    );
};

export default PreviewImage;
