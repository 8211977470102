import React from 'react';
import { View, Text, StyleSheet, Image } from 'react-native';

const CrestZone = ({ crestZone, compositeBackgroundColor }) => {
  return (
    <View style={[styles.container, { backgroundColor: compositeBackgroundColor, margin: 20 }]}>
      <View style={styles.headerText}>
        <Text style={crestZone.orgTextStyle}>
          {crestZone.orgName}
        </Text>
        <Text style={crestZone.chapterTextStyle}>
          {crestZone.chapterName}
        </Text>
      </View>
      <View style={styles.border}>
        <Text style={crestZone.yearTextStyle}>
          {crestZone.years.from}
        </Text>
        <Image
          source={{ uri: crestZone.crestImageUrl }}
          style={styles.image}
        />
        <Text style={crestZone.yearTextStyle}>
          {crestZone.years.to}
        </Text>
      </View>
      <View style={styles.border}>
        <Text style={crestZone.universityTextStyle}>
          {crestZone.universityName}
        </Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 10,
    // alignSelf: 'center',
    // backgroundColor: '#fff',
    // backfaceVisibility: true,
    // flex: -1,
    // flexShrink: 0,
    // justifyContent: 'space-between',
    // alignItems: 'space-between',
    // alignContent: 'space-between',
    // width: "8in",
    // height: "11in",
    // textAlign: 'center',
    // borderWidth: 1,
  },
  border: {
    // flex: 1
    // borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'row',
  },
  headerText: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  image: { width: 300, height: 300, resizeMode: 'contain' },
});

export default CrestZone;
